import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Config } from '../contracts';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ListResponse } from '../contracts/list-response';
import { Notification } from '../contracts/notification';

@Injectable({
  providedIn: 'root',
})
export class ConfigDataService extends BaseService<Config> {
  path = 'config';

  get(key: string): Observable<Config> {
    const params = key ? new HttpParams().set('key', key) : {};
    return this.http.get<Config>(`${this.apiConfig.baseUrl}/${this.path}/get`, {
      params,
    });
  }

  all(): Observable<ListResponse<Config>> {
    return this.http.get<ListResponse<Config>>(`${this.apiConfig.baseUrl}/${this.path}/list`);
  }

  version(): Observable<string> {
    return this.http.get<string>(`${this.apiConfig.baseUrl}/config/version`);
  }
}
