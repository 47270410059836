import { Model } from './model';
import { OrderMatch } from './order-match';
import { ContractTemplate } from './contract-template';
import { PriceTemplate } from './price-template';
import { TareTemplate } from './tare-template';
import { Relation } from './relation';
import { RelationCertificate } from './relation-certificate';
import { Unit } from './unit';
import { Order, Pool } from '@ppa/data';

export interface Contract extends Model {
  order: Order;
  orderMatch?: OrderMatch;
  contractTemplate: ContractTemplate;
  referenceNumber: string;
  contractDate: string;
  origin: string;
  deliveryPeriod: string;
  deliveryCondition: string;
  amount?: string;
  disinfected?: boolean;
  yield: number;
  harvestYear: string;
  priceType: PriceType;
  price: string;
  priceTemplate: PriceTemplate;
  priceAgreement: string;
  vat: number;
  vatIncluded: boolean;
  onionClassification: string;
  minimumGrit: number;
  tareLocation: string;
  countryOfOrigin?: string;
  weighingCosts: string;
  sproutInhibitor: boolean;
  tareTemplate: TareTemplate;
  taring: string;
  termOfPayment: number;
  relation: Relation;
  certification?: Partial<RelationCertificate>[];
  attachCertificationInMail?: boolean;
  commission?: string;
  commissionPaidBy?: string;
  commissionVat?: number;
  comment?: string;
  finalBillDrawnUpBy?: string;
  attachConditionsInMail?: boolean;
  ownNumberingForRelation?: string;
  // purchaseContract?: boolean;
  // purchaseContractOrder?: number;
  amountUnit?: Unit;
  amountOfUnit?: string;
  pool?: Pool;
  attachments?: [];
  cancelReason?: string;
  cancelReasonOther?: string;
  canceledAt?: string;
  contractSentAt?: string;
  sentContract?: boolean;
  invoiceReceiverType?: string;
  loadingMethod?: string;
  extraSeedingUntil?: string;
  extraSeedingPaidBy?: string;
  cultivationGuidance?: string;
  cultivationGuidancePaidBy?: string;
  minLengthRoot?: number;
  maxLengthRoot?: number;
  minWidthRoot?: number;
  maxWidthRoot?: number;
  targetHeadLength?: string;
  backBreeding?: string;
  minPlantsPerHa?: number;
  maxPlantsPerHa?: number;
  treatmentPaidBy?: string;
  mineralInvestigationPaidBy?: string;
  tareBy?: string;
}

export enum PriceType {
  Fixed = 'fixed',
  Agreement = 'agreement',
}

export enum ContractTypes {
  Commission = 'commission',
  Trade = 'trade',
  Pool = 'pool',
  Single = 'single',
}

export enum CancelContract {
  Other = 'other',
}
