import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import {
  Certificate,
  CertificateDataService,
  COMMISSION_SHORTCODE,
  Contract,
  ContractTemplate,
  ContractTypes,
  Country,
  KeyValuePairs,
  Order,
  OrderMatch,
  OrderType,
  PriceTemplate,
  PriceTemplateDataService,
  PriceType,
  Relation,
  RelationCertificate,
  TareTemplate,
  TareTemplateDataService,
  NoteTemplate,
  NoteTemplateDataService,
  Unit,
  VatCode,
  ConfigService,
  UserOption,
  UserDataService,
  PriceUnitIdentifier,
  Treatment,
  Product,
  ContractTemplateField,
} from '@ppa/data';
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  publishReplay,
  shareReplay,
  skip,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CertificateService } from '../../../../services/certificate.service';
import { CountryService } from '../../../../services/country.service';
import { ScrollService } from '../../../../services/scroll-service';
import { UnitService } from '../../../../services/unit.service';
import { VatCodeService } from '../../../../services/vat-code.service';
import { RelationService } from '../../../relation/services/relation.service';
import { ContractService } from '../../services/contract.service';
import { DossierService } from '../../../dossier/services/dossier.service';
import { FormIsRequiredComponent } from '../../../../components/form-is-required/form-is-required.component';
import { ConfirmDialogComponent, Intention, ModalService } from '@ppa/layout';
import * as moment from 'moment';

@Component({
  selector: 'ppa-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss'],
})
export class ContractFormComponent extends FormIsRequiredComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  private readonly subscriptions: Subscription[] = [];

  @Input() contract: Contract;

  @Input() order: Order;
  @Input() orderMatch: OrderMatch;
  @Input() isPurchaseContract = false;

  @Output() save: EventEmitter<Contract> = new EventEmitter<Contract>();

  @ViewChild('form') form: NgForm;

  readonly contractForm: FormGroup;
  readonly priceType = PriceType;
  readonly currentRelation$ = new BehaviorSubject<Relation>(null);
  readonly relations$: Observable<Relation[]>;
  readonly priceTemplates$: Observable<PriceTemplate[]>;
  readonly tareTemplates$: Observable<TareTemplate[]>;
  readonly noteTemplates$: Observable<NoteTemplate[]>;
  readonly priceUnitOptions$: Observable<Unit[]>;
  readonly selectedPriceType$: Observable<string>;
  readonly commissionContract$: Observable<boolean>;
  readonly deliveryOptions: KeyValuePairs<string>;
  readonly toStorageOptions: KeyValuePairs<string>;
  readonly onionClassificationOptions: KeyValuePairs<string>;
  readonly tareLocationOptions: KeyValuePairs<string>;
  readonly weighingCostsOptions: KeyValuePairs<string>;
  readonly sproutInhibitorOptions: KeyValuePairs<string>;
  readonly priceTypeOptions: KeyValuePairs<string>;
  readonly commissionPaidByOptions: KeyValuePairs<string>;
  readonly cultivationPaidByOptions: KeyValuePairs<string>;
  readonly cultivationOptions: KeyValuePairs<string>;
  readonly finalBillDrawnUpByOptions: KeyValuePairs<string>;
  readonly tareByOptions: KeyValuePairs<string>;
  readonly loadingMethodOptions: KeyValuePairs<string>;
  readonly backBreedingOptions: KeyValuePairs<string>;
  readonly amountUnitOptions$: Observable<Unit[]>;
  readonly yieldUnitOptions$: Observable<Unit[]>;
  readonly vatCodes$: Observable<VatCode[]>;
  readonly amountTypeOptions: KeyValuePairs<string>;

  relationCertificates$: Observable<Partial<RelationCertificate>[]>;
  countries$: Observable<Country[]>;
  packagingOptions: KeyValuePairs<string>;
  mediators$: Observable<UserOption[]>;
  treatments$: Observable<Treatment[]>;
  sizeSorting$: Observable<string[]>;
  contractFields$: Observable<ContractTemplateField[]>;

  readonly vatSelectCompareFn: (v1: any, v2: any) => boolean;

  breadcrumbs$: Observable<string[]>;
  afasRelationCheck$: Observable<{ afasRelation: boolean }>;

  isSentContract = false;
  isCommission = false;
  displayPool: boolean;
  displayPoolFinalPaymentDate: boolean;

  displayTonSuffix$ = new BehaviorSubject<boolean>(true);

  contractConditionalFields: Map<string, string | null> = new Map<string, string | null>();

  get controls() {
    return this.contractForm.controls;
  }

  get certification() {
    return this.contractForm.get('certification') as FormArray;
  }

  get attachments() {
    return this.contractForm.get('attachments') as FormArray;
  }

  constructor(
    protected fb: FormBuilder,
    private contractService: ContractService,
    private certificateDataService: CertificateDataService,
    private relationService: RelationService,
    private priceTemplateDataService: PriceTemplateDataService,
    private tareTemplateDataService: TareTemplateDataService,
    private noteTemplateDataService: NoteTemplateDataService,
    private vatCodeService: VatCodeService,
    private scrollService: ScrollService,
    private unitService: UnitService,
    private certificateService: CertificateService,
    private countryService: CountryService,
    private config: ConfigService,
    private dossierService: DossierService,
    private userDataService: UserDataService,
    protected modalService: ModalService,
  ) {
    super();
    const currentYearJune = moment().month(5).date(15);
    this.countries$ = this.countryService.getCountries();
    this.contractForm = fb.group({
      // ALGEMEEN
      contractTemplate: [{ value: null, disabled: true }, [Validators.required]],
      referenceNumber: [
        { value: translate('modules.contract.create.reference_number_auto_generated'), disabled: true },
      ],
      contractDate: [new Date(), [Validators.required]],
      mediator: [{ value: null, disabled: false }, [Validators.required]],
      relation: [{ value: null, disabled: true }],

      // POOL
      pool: [],
      poolName: [{ value: null, disabled: true }] /** Only used for display purpose */,
      poolFinalPaymentDate: [{ value: null, disabled: true }, [Validators.required]],

      // COMMISSION
      commission: [
        { value: this.getConfigCommission(), disabled: true },
        [Validators.required, Validators.pattern(/^\d+(([.,])\d{1,5})?$/)],
      ],
      commissionPercentage: [{ value: false, disabled: true }],
      commissionPriceUnit: [{ value: this.getConfigCommissionUnit(), disabled: true }, [Validators.required]],
      commissionPaidBy: [{ value: 'buyer', disabled: true }, [Validators.required]],
      finalBillDrawnUpBy: [{ value: 'buyer', disabled: false }, [Validators.required]],
      ownNumberingForRelation: [{ value: null, disabled: false }],

      // CONTENT
      originList: [null],
      origin: [null],
      harvestYear: [{ value: this.getConfigCurrentYear(), disabled: false }, [Validators.pattern(/^[\d+]{4}$/)]],
      deliveryYear: [{ value: null, disabled: false }, [Validators.pattern(/^[\d+]{4}$/)]],
      amountType: [null],
      amount: [null, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      amountOfUnit: [
        { value: null, disabled: false },
        [Validators.required, Validators.pattern(/^\d+(([.,])\d{1,2})?$/)],
      ],
      amountUnit: [{ value: null, disabled: false }, [Validators.required]],
      yield: [null, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      yieldUnit: [{ value: null, disabled: false }],
      priceType: [null, [Validators.required]],
      price: [{ value: null, disabled: true }, [Validators.required, Validators.pattern(/^\d+(([.,])\d{1,2})?$/)]],
      priceUnit: [{ value: null, disabled: true }, [Validators.required]],
      priceTemplate: [{ value: null, disabled: true }],
      priceAgreement: [{ value: null, disabled: true }, [Validators.required]],
      vatCode: [{ value: null, disabled: false }],

      deliveryCondition: ['on_car'],
      deliveryPeriod: [null],
      onionClassification: [null],
      minimumGrit: [35, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      sizeSorting: [null],
      disinfected: [{ value: null, disabled: false }],
      treatment: [{ value: null, disabled: false }],
      sproutInhibitor: [null],
      tareTemplate: [null],
      taring: [null],
      tareLocation: ['buyer'],
      packaging: [{ value: null, disabled: false }],
      weighingCosts: ['seller'],
      termOfPayment: [{ value: this.getConfigTermsOfPayment(), disabled: true }, [Validators.pattern(/^\d+$/)]],
      noteTemplate: [null],
      notes: [null],
      countryOfOrigin: [null],

      // Teelt
      seedsPaidBy: [null],
      extraSeedingUntil: [{ value: currentYearJune }],
      extraSeedingPaidBy: [null],
      cultivationGuidanceList: [null],
      cultivationGuidance: [null],
      cultivationGuidancePaidBy: [null],
      minLengthRoot: [null],
      maxLengthRoot: [null],
      minWidthRoot: [null],
      maxWidthRoot: [null],
      targetHeadLength: [null],
      backBreeding: [null],
      minPlantsPerHa: [null],
      maxPlantsPerHa: [null],
      treatmentPaidBy: [null],
      mineralInvestigationPaidBy: [null],
      tareBy: [null],
      loadingMethod: [null],

      // UITGESCHAKELD, LIJKT NIET NODIG / GEBRUIKT TE WORDEN
      vatIncluded: [false],
      toStorage: [{ value: null, disabled: true }],

      // CERTIFICATION
      certification: this.fb.array([]),

      // ATTACHMENTS
      attachCertificationInMail: [{ value: true }],
      attachConditionsInMail: [{ value: true }],
      attachments: this.fb.array([]),
    });

    this.relations$ = this.relationService.getRelations();
    this.priceTemplates$ = this.priceTemplateDataService
      .getPriceTemplateOptions()
      .pipe(map((templates) => this.mapTemplates(templates, 'price')));

    this.tareTemplates$ = this.tareTemplateDataService.getTareTemplateOptions().pipe(
      map((templates) => this.mapTemplates(templates, 'tare')),
      tap((templates) => {
        // On a new contract when no template is selected, select the first received
        if (!this.contract && !this.contractForm.get('tareTemplate').value) {
          const relation = this.currentRelation$.getValue();
          let selectedTemplate = templates[0];

          if (relation) {
            let found = false;
            for (const template of templates) {
              if (template.relation_id === relation.id && template.defaultTemplate) {
                found = true;
                selectedTemplate = template;
              }
            }

            if (!found) {
              for (const template of templates) {
                if (template.relation_id === relation.id) {
                  found = true;
                  selectedTemplate = template;
                }
              }
            }
          }

          this.contractForm.get('tareTemplate').setValue(selectedTemplate);
        }
      }),
    );

    this.noteTemplates$ = this.noteTemplateDataService.getNoteTemplateOptions().pipe(
      map((templates) => this.mapTemplates(templates, 'note')),
      tap((templates) => {
        // On a new contract when no template is selected, select the first received
        if (!this.contract && !this.contractForm.get('noteTemplate').value) {
          // Do not select the first template
          // this.contractForm.get('noteTemplate').setValue(templates[0]);
        }
      }),
    );

    this.priceUnitOptions$ = this.unitService.getPriceUnits();
    this.amountUnitOptions$ = this.unitService.getAmountUnits();
    this.yieldUnitOptions$ = this.unitService.getYieldUnits();

    this.deliveryOptions = this.contractService.getDeliveryConditionOptions();
    this.toStorageOptions = this.contractService.getToStorageOptions();
    this.onionClassificationOptions = this.contractService.getOnionClassificationOptions();
    this.tareLocationOptions = this.contractService.getTareLocationOptions();
    this.weighingCostsOptions = this.contractService.getWeighingCostsOptions();
    this.sproutInhibitorOptions = this.contractService.getSproutInhibitorOptions();
    this.priceTypeOptions = this.contractService.getPriceTypeOptions();
    this.commissionPaidByOptions = this.contractService.getCommissionPaidByOptions();
    this.cultivationPaidByOptions = this.contractService.getCultivationPaidByOptions();
    this.cultivationOptions = this.contractService.getCultivationOptions();
    this.amountTypeOptions = this.contractService.getAmountTypeOptions();
    this.finalBillDrawnUpByOptions = this.contractService.getFinalBillDrawnUpByOptions();
    this.packagingOptions = this.dossierService.packagingOptions;
    this.mediators$ = this.userDataService.options();
    this.tareByOptions = this.contractService.getTareByOptions();
    this.loadingMethodOptions = this.contractService.getLoadingMethodOptions();
    this.backBreedingOptions = this.contractService.getBackBreedingOptions();

    this.selectedPriceType$ = this.contractForm.get('priceType').valueChanges.pipe(publishReplay(1));
    this.subscriptions.push((this.selectedPriceType$ as ConnectableObservable<PriceType>).connect());

    this.commissionContract$ = this.contractForm.get('contractTemplate').valueChanges.pipe(
      distinctUntilChanged(),
      switchMap((contractTemplate: ContractTemplate) => {
        this.isCommission = contractTemplate?.shortCode === COMMISSION_SHORTCODE;
        return of(contractTemplate?.shortCode === COMMISSION_SHORTCODE);
      }),
      publishReplay(1),
    );
    this.subscriptions.push((this.commissionContract$ as ConnectableObservable<boolean>).connect());

    this.vatSelectCompareFn = (v1: VatCode, v2: VatCode) => v1.percentage === v2.percentage;

    this.vatCodes$ = this.vatCodeService
      .getVatCodes({ orderType: OrderType.Buy })
      .pipe(tap((vatCodes) => this.setVatCodeDefault(vatCodes)));
  }

  ngOnInit(): void {
    this.sizeSorting$ = this.dossierService.setSizeSortingOptions(this.order.product.defaultValues);
    this.treatments$ = this.dossierService.setTreatmentOptions(this.order.product.defaultValues).pipe(shareReplay(1));

    this.selectedPriceType$.pipe(takeUntil(this.destroy$)).subscribe(this.onSelectedPriceTypeChange.bind(this));

    this.contractForm
      .get('priceTemplate')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(this.onPriceTemplateChange.bind(this));

    this.contractForm
      .get('tareTemplate')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(this.onTareTemplateChange.bind(this));

    this.contractForm
      .get('noteTemplate')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(this.onNoteTemplateChange.bind(this));

    this.commissionContract$.pipe(takeUntil(this.destroy$)).subscribe(this.onContractTemplateChange.bind(this));

    if (!this.contract && this.order) {
      this.contractForm.get('minimumGrit').patchValue(this.order.product.minGrit);

      const priceUnitDefaultValue = this.dossierService.getDefaultValue(this.order.product.defaultValues, 'priceUnit');
      if (priceUnitDefaultValue) {
        this.priceUnitOptions$.subscribe((units) => {
          const unit = units.find((amountUnit) => amountUnit.identifier === priceUnitDefaultValue);
          if (unit) {
            this.contractForm.get('priceUnit').patchValue(unit);
          }
        });
      }

      const commissionPaidByDefaultValue = this.dossierService.getDefaultValue(
        this.order.product.defaultValues,
        'commissionPaidBy',
      );
      if (commissionPaidByDefaultValue) {
        this.contractForm.get('commissionPaidBy').patchValue(commissionPaidByDefaultValue);
      }
    }

    if (!this.contract && this.orderMatch) {
      this.contractForm.get('amount').setValue(this.orderMatch.amount);

      const relation = this.order.relation;

      this.afasRelationCheck$ = this.relationService.afasRelationCheck(relation.id);

      if (relation) {
        if (relation.commissionAmount) {
          this.contractForm.get('commission').setValue(relation.commissionAmount);
        }
        if (relation.commissionAmountPriceUnit) {
          this.contractForm.get('commissionPriceUnit').setValue(relation.commissionAmountPriceUnit);
        }
        if (relation.paymentCondition) {
          this.contractForm.get('termOfPayment').setValue(relation.paymentCondition);
        }
      }
    } else if (!this.contract && this.order) {
      this.contractForm.get('amount').setValue(this.order.amount);
      this.afasRelationCheck$ = this.relationService.afasRelationCheck(this.order.relation.id);
    } else {
      // in edit mode
      this.afasRelationCheck$ = of({ afasRelation: true });
    }

    let salesOrderCertificates = [];
    if (this.order) {
      const order = this.getSellingOrder();
      this.prefillContractWithOrder(order);
      salesOrderCertificates = order.certification;
    }

    if (this.contract) {
      this.contractForm.patchValue(this.contract);
      this.controls.originList.patchValue(this.contract.origin);
      this.currentRelation$.next(this.contract.relation);
      this.currentRelation$.next(this.contract.relation);
      this.displayTonSuffix$.next(this.contract.amountUnit?.divisor !== null);

      this.controls.cultivationGuidanceList.patchValue(this.contract.cultivationGuidance);

      this.addAttachmentsFormControl(this.contract.attachments);
    } else {
      if (this.order) {
        this.currentRelation$.next(this.order.otherRelation ? this.order.otherRelation : this.order.relation);
        this.displayTonSuffix$.next(this.order.amountUnit?.divisor !== null);
      }
    }
    this.setupUnitFields();

    if (this.isPurchaseContract) {
      this.contractForm.get('toStorage').enable();
    }

    if (this.contractForm.get('extraSeedingUntil').value === null) {
      const currentYearJune = moment().month(5).date(15);

      this.contractForm.get('extraSeedingUntil').patchValue(currentYearJune);
    }

    this.relationCertificates$ = this.getRelationCertificates(
      this.getContractRelation(this.isCommission),
      salesOrderCertificates,
    );

    if (!this.contract) {
      this.setCountryOfOrigin();
      this.setSellerLocation();
    }

    this.breadcrumbs$ = this.getBreadcrumbs();
    this.setPool();
    this.setDeliveryTermEnabled();
    this.setSentContract();

    this.contractFields$ = this.contractService
      .getContractFields(this.getContractTemplate(), this.currentRelation$.getValue(), this.getContractProduct())
      .pipe(tap((fields) => this.setFields(fields)));
    this.contractFields$.subscribe();

    this.controls.originList.valueChanges.subscribe((value) => {
      if (value !== 'OTHER') {
        this.controls.origin.patchValue(value);
      }
    });

    this.controls.cultivationGuidanceList.valueChanges.subscribe((value) => {
      if (value !== 'OTHER') {
        this.controls.cultivationGuidance.patchValue(value);
      }
    });
  }

  private getContractType(): ContractTypes {
    return this.contract ? this.contract.order?.contractType : this.order.contractType;
  }

  private getOrderType(): OrderType {
    return this.contract ? this.contract.order?.orderType : this.order.orderType;
  }

  private getContractTemplate(): ContractTemplate {
    return this.contract ? this.contract.contractTemplate : this.order.contractTemplate;
  }

  private getContractProduct(): Product {
    return this.order.product;
  }

  private setFields(fields: ContractTemplateField[]): void {
    this.contractConditionalFields = new Map<string, string | null>();
    const fieldKeys = Object.keys(fields);
    fieldKeys.forEach((fieldKey) => {
      const field = fields[fieldKey];
      field.fields.forEach((subField) => {
        this.contractConditionalFields.set(subField, null);

        const formField = this.contractForm.get(subField);
        if (formField && field.required) {
          formField.setValidators([Validators.required]);
          formField.updateValueAndValidity();
        }
      });
    });
  }

  private setSentContract(): void {
    this.isSentContract = this.contract ? this.contract.sentContract : false;
  }

  private setDeliveryTermEnabled(): void {
    const termOfPayment = this.contractForm.get('termOfPayment');
    const poolFinalPaymentDate = this.contractForm.get('poolFinalPaymentDate');

    if (this.getContractType() === ContractTypes.Pool && this.getOrderType() === OrderType.Sell) {
      if (this.contractConditionalFields.has('poolFinalPaymentDate')) {
        poolFinalPaymentDate.enable();
      }
    } else {
      termOfPayment.enable();
    }
  }

  private setPool(): void {
    const contractType = this.getContractType();
    this.displayPool = contractType === ContractTypes.Pool;
    this.displayPoolFinalPaymentDate = this.displayPool && this.getOrderType() === OrderType.Sell;

    if (contractType === ContractTypes.Pool) {
      const poolFormGroup = this.contractForm.get('pool');
      const poolName = this.contractForm.get('poolName');

      if (this.contract) {
        poolFormGroup.patchValue(this.contract.pool);
        poolName.patchValue(this.contract.pool.name);
      } else {
        poolFormGroup.patchValue(this.order.pool);
        poolName.patchValue(this.order.pool.name);
      }
    }

    if (!this.contractConditionalFields.has('poolFinalPaymentDate')) {
      this.contractForm.get('poolFinalPaymentDate').disable();
    } else {
      this.contractForm.get('poolFinalPaymentDate').enable();
    }
  }

  private getBreadcrumbs(): Observable<string[]> {
    let contractTypeLabel: string;
    if (this.order) {
      const { contractType } = this.order;
      contractTypeLabel = translate(`modules.order.create.contract_type.${contractType}`);
    } else if (this.contract) {
      const { contractTemplate } = this.contract;
      contractTypeLabel = contractTemplate.title;
    }

    const contractLabel = this.contract
      ? translate(`modules.contract.edit.breadcrumb`)
      : translate(`modules.contract.create.breadcrumb`);
    return of([contractLabel, contractTypeLabel]);
  }

  private setSellerLocation(): void {
    const order = this.getSellingOrder() ?? this.order;
    if (!order) {
      return;
    }

    const { dossier } = order;
    if (dossier) {
      const { location } = dossier;
      const { city, street, number } = location[0];
      this.contractForm.get('origin').patchValue(`${street} ${number}, ${city}`);
    }
  }

  private setCountryOfOrigin(): void {
    if (this.orderMatch) {
      const sellingOrder = this.getSellingOrder();
      const { country: countryShortName } = sellingOrder.relation;

      this.countryService
        .getCountryByShortName(countryShortName)
        .pipe(
          take(1),
          filter((country) => country !== undefined),
        )
        .subscribe((country) => this.contractForm.get('countryOfOrigin').patchValue(country.name));
    }
  }

  private setVatCodeDefault(vatCodes: VatCode[]): void {
    this.contractForm.get('vatCode').patchValue(vatCodes.find((vatCode) => vatCode.percentage === 9));
  }

  private getSellingOrder(): Order {
    if (this.order.orderType === OrderType.Sell) {
      return this.order;
    }
    if (this.orderMatch) {
      const oppositeOrder = this.getOppositeOrderFromOrderMatch();
      if (oppositeOrder.orderType === OrderType.Sell) {
        return oppositeOrder;
      }
    }
    return this.order;
  }

  private getOppositeOrderFromOrderMatch(): Order {
    return this.orderMatch?.matchedOrder ?? this.orderMatch?.order;
  }

  private getRelationCertificates(
    relation$: Observable<Relation>,
    salesOrderCertification: Certificate[],
  ): Observable<Partial<RelationCertificate>[]> {
    const certificateOptions$ = this.certificateService.getCertificateOptions();
    const relationCertificates$ = relation$.pipe(pluck('certificates'));
    const contractCertificates$ = of(this.contract).pipe(pluck('certification')) as Observable<Certificate[]>;

    const certificates$ = this.contract
      ? this.getRelationCertificatesCheckedByContract(contractCertificates$, certificateOptions$, relationCertificates$)
      : this.getRelationCertificatesUnChecked(certificateOptions$, relationCertificates$, salesOrderCertification);

    return certificates$.pipe(tap((relationCertificates) => this.addCertificateFormControls(relationCertificates)));
  }

  private getRelationCertificatesUnChecked(
    certificateOptions$: Observable<Certificate[]>,
    relationCertificates$: Observable<RelationCertificate[]>,
    salesOrderCertification: Certificate[],
  ) {
    return combineLatest([certificateOptions$, relationCertificates$]).pipe(
      map((set) => {
        const [certificateOptions, relationCertificates] = set;
        return this.certificateService.combineCertificateSources(
          relationCertificates,
          certificateOptions,
          salesOrderCertification,
        );
      }),
    );
  }

  private getRelationCertificatesCheckedByContract(
    contractCertificates$: Observable<Certificate[]>,
    certificateOptions$: Observable<Certificate[]>,
    relationCertificates$: Observable<RelationCertificate[]>,
  ) {
    return combineLatest([relationCertificates$, certificateOptions$, contractCertificates$]).pipe(
      map((set) => {
        const [relationCertificates, certificateOptions, contractCertificates] = set;
        return this.certificateService.combineCertificateSources(
          relationCertificates,
          certificateOptions,
          contractCertificates.map((contractCertificate) =>
            certificateOptions.find((certificate) => certificate.id === contractCertificate.id),
          ),
          relationCertificates.map((relationCertificate) =>
            contractCertificates.find((certificate) => certificate.id === relationCertificate.certificate.id),
          ),
        );
      }),
    );
  }

  private getContractRelation(commission = false): Observable<Relation> {
    if (this.contract) {
      return of(this.contract.relation);
    } else if (this.orderMatch) {
      return of(this.order.relation);
    } else if (!this.orderMatch && this.order) {
      return of(this.order.relation);
    } else {
      throw new Error('Contract has no relation!');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private addCertificateFormControls(certificates: Partial<RelationCertificate>[]): void {
    this.certification.clear();
    certificates.map((certificate) => {
      this.certification.push(this.fb.group(certificate));
    });
  }

  private transformCertificateInput(certificationInputs: any[]): Certificate[] {
    const encounters: number[] = [];
    return certificationInputs
      .filter((certificationInput) => certificationInput?.certificate?.checked)
      .filter((c) => {
        const duplicate = encounters.some((e) => e === c.certificate.id);
        encounters.push(c.certificate.id);
        return !duplicate;
      });
  }

  private transform(contractForm: FormGroup): any {
    const amount = contractForm.get('amount').value.toString();
    const amountOfUnit = contractForm.get('amountOfUnit').value.toString();

    return {
      ...contractForm.value,
      ...(amount && { amount: amount.toString().replace(',', '.') }),
      ...(amountOfUnit && { amountOfUnit: amountOfUnit.toString().replace(',', '.') }),
    };
  }

  handleSubmit() {
    console.log(this.contractForm.valid, this.contractForm);
    if (this.contractForm.valid) {
      if (this.isSentContract) {
        this.modalService
          .createModal(ConfirmDialogComponent, {
            message: translate('modules.contract.modal.create.change_warning'),
            confirmButtonText: translate('modules.contract.buttons.duplicate_contract_confirm'),
            buttonColor: 'primary',
          })
          .dialog.onSave()
          .then(() => {
            this.saveContract();
          });
      } else {
        this.saveContract();
      }
    } else {
      this.scrollService.scrollToFirstInvalid(this.contractForm);
    }
  }

  private saveContract() {
    this.save.emit({
      ...this.transform(this.contractForm),
      certification: this.certificateService.transformCertificationInput(this.certification.value),
    });
  }

  openRelationModal() {
    const relationControl = this.contractForm.get('relation');
    relationControl.markAsTouched();

    this.relationService
      .selectRelation()
      .pipe(take(1))
      .subscribe((relation) => {
        if (typeof relation === 'object') {
          this.currentRelation$.next(relation as Relation);
          relationControl.patchValue(relation);
          relationControl.markAsDirty();
        }
      });
  }

  /**
   * Switches between the price type's related fields
   */
  private onSelectedPriceTypeChange(selectedPriceType: PriceType) {
    const priceField = this.contractForm.get('price');
    const priceUnitField = this.contractForm.get('priceUnit');
    const priceTemplateField = this.contractForm.get('priceTemplate');
    const priceAgreementField = this.contractForm.get('priceAgreement');

    priceField.disable();
    priceUnitField.disable();
    priceTemplateField.disable();
    priceAgreementField.disable();

    if (selectedPriceType === PriceType.Fixed) {
      priceField.enable();
      priceUnitField.enable();
    } else if (selectedPriceType === PriceType.Agreement) {
      priceTemplateField.enable();
      priceAgreementField.enable();
    }
  }

  /**
   * @todo: show confirm dialog before overriding value?
   */
  private onPriceTemplateChange(selectedPriceTemplate: PriceTemplate) {
    if (selectedPriceTemplate) {
      this.contractForm.get('priceAgreement').patchValue(selectedPriceTemplate.description);
    }
  }

  /**
   * @todo: show confirm dialog before overriding value?
   */
  private onTareTemplateChange(selectedTareTemplate: TareTemplate) {
    if (selectedTareTemplate) {
      this.contractForm.get('taring').patchValue(selectedTareTemplate.description);
    }
  }

  /**
   * @todo: show confirm dialog before overriding value?
   */
  private onNoteTemplateChange(selectedNoteTemplate: NoteTemplate) {
    if (selectedNoteTemplate) {
      this.contractForm.get('notes').patchValue(selectedNoteTemplate.description);
    }
  }

  private onContractTemplateChange(commissionContract: boolean) {
    const relationField = this.contractForm.get('relation');
    if (commissionContract) {
      relationField.disable();
    } else {
      relationField.enable();
    }

    [
      // 'attachCertificationInMail', verplaatst naar bijlagen
      'commission',
      'commissionPercentage',
      'commissionPriceUnit',
      'commissionPaidBy',
      // 'finalBillDrawnUpBy',
      // 'attachConditionsInMail', verplaatst naar bijlagen
      // 'ownNumberingForRelation', verplaatst naar algemeen
    ].forEach((commissionField) => {
      const field = this.contractForm.get(commissionField);
      if (commissionContract) {
        field.enable();
      } else {
        field.disable();
      }
    });
  }

  private prefillContractWithOrder(order: Order) {
    const orderToContractMapping = {
      minimumGrit: 'minimumGrit',
      contractTemplate: 'contractTemplate',
      harvestYear: 'harvestYear',
      onionClassification: 'quality',
      yield: 'surface',
      sproutInhibitor: 'sproutInhibitor',
      price: 'indicationPrice',
      priceUnit: 'indicationPriceUnit',
      amountOfUnit: 'amountOfUnit',
      amountUnit: 'amountUnit',
      packaging: 'packaging',
      mediator: 'mediator',
      treatment: 'treatment',
    };

    Object.keys(orderToContractMapping).forEach((key: string) => {
      const value = order[orderToContractMapping[key]];
      if (value) {
        const field = this.contractForm.get(key);
        if (field) {
          field.patchValue(value);
        }
      }
    });
  }

  private setupUnitFields() {
    // when this is an edit form skip the first emits from the amountOfUnit and amountUnit
    combineLatest([
      this.contractForm.get('amountOfUnit').valueChanges.pipe(filter(Boolean), debounceTime(1000)) as Observable<
        string
      >,
      this.contractForm.get('amountUnit').valueChanges.pipe(filter(Boolean)) as Observable<Unit>,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([amount, unit]) => {
        if (!amount || !unit) {
          return;
        }
        amount += '';
        const amountFields = [this.contractForm.get('amount')];
        // only one of these fields is enabled
        const enabledField = amountFields.find((f) => f.enabled);
        if (enabledField && (enabledField.value == null || enabledField.value === '')) {
          const forceAmount = this.dossierService.getDefaultValue(this.order.product.defaultValues, 'forceAmount');
          if (unit.identifier === PriceUnitIdentifier.Price_unit_per_stuk || forceAmount) {
            enabledField.setValue(amount);
            this.displayTonSuffix$.next(false);
          } else if (unit.divisor === null) {
            enabledField.setValue(null);
            this.displayTonSuffix$.next(true);
          } else {
            const convertedAmount = parseFloat(amount.toString().replace(',', '.'));
            enabledField.setValue(((convertedAmount * unit.divisor) / 1000).toFixed(2));
            this.displayTonSuffix$.next(true);
          }
        }
      });

    this.contractForm.get('amountOfUnit').updateValueAndValidity();
    this.contractForm.get('amountUnit').updateValueAndValidity();
  }

  private getConfigCommission(): number {
    let commission = null;

    if (this.config.hasOwnProperty('commission')) {
      commission = parseFloat(this.config.commission);
    }

    return commission;
  }

  private getConfigCommissionUnit(): string {
    let commission_unit = null;

    if (this.config.hasOwnProperty('commission_unit')) {
      commission_unit = this.config.commission_unit;
    }

    return commission_unit;
  }

  private getConfigCurrentYear(): number {
    let date = new Date().getFullYear();

    if (this.config.hasOwnProperty('harvest_year')) {
      date = parseInt(this.config.harvest_year, 10);
    }

    return date;
  }

  private getConfigTermsOfPayment(): number {
    let termsOfPayment = 60;

    if (this.config.hasOwnProperty('payment_terms')) {
      termsOfPayment = parseInt(this.config.payment_terms, 10);
    }

    return termsOfPayment;
  }

  private mapTemplates(templates, templateType = 'price') {
    return templates.map((template) => {
      template.name =
        (template.company
          ? template.company
          : translate('modules.contract.create.' + templateType + '_template_general')) +
        ' - ' +
        template.title;
      return template;
    });
  }

  private addAttachmentsFormControl(attachments: []): void {
    this.attachments.clear();
    attachments.map((attachment) => {
      this.attachments.push(this.fb.group(attachment));
    });
  }

  onHandleAttachments(attachments) {
    this.addAttachmentsFormControl(attachments);
  }

  public calculateAmount(forceAmount: boolean = false) {
    const amountFields = [this.contractForm.get('amount')];
    const enabledField = amountFields.find((f) => f.enabled);
    if (enabledField && (enabledField.value == null || enabledField.value === '' || forceAmount)) {
      if (forceAmount) {
        enabledField.patchValue('');
      }
      this.contractForm.get('amountOfUnit').updateValueAndValidity();
    }
  }
}
