import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Invoice } from '../contracts/invoice';
import { InvoiceRow } from '../contracts/invoice-row';
import { ListResponse } from '../contracts/list-response';
import { Observable } from 'rxjs';
import { ReadResponse } from '../contracts/read-response';
import { ContractTemplateVariant, InvoiceForm, OrderMatch } from '../contracts';
import { tap } from 'rxjs/operators';
import { DownloadFileService } from './download-file.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataService extends BaseService<Invoice> {
  path = 'invoices';

  getInvoiceRows(invoideId: number): Observable<ListResponse<InvoiceRow>> {
    return this.http.get<ListResponse<InvoiceRow>>(`${this.apiConfig.baseUrl}/${this.path}/${invoideId}/rows`);
  }

  createInvoice(invoiceRows: InvoiceRow[], invoiceForm: InvoiceForm): Observable<ReadResponse<Invoice>> {
    return this.http.post<ReadResponse<Invoice>>(`${this.apiConfig.baseUrl}/${this.path}/create`, {
      invoiceRows,
      invoiceForm,
    });
  }

  patchInvoiceRows(
    invoiceId: number,
    invoiceRows: InvoiceRow[],
    invoiceForm: InvoiceForm,
  ): Observable<ListResponse<InvoiceRow>> {
    return this.http.patch<ListResponse<InvoiceRow>>(`${this.apiConfig.baseUrl}/${this.path}/${invoiceId}/rows`, {
      invoiceRows,
      remarks: invoiceForm.remarks,
      relation: invoiceForm.relation,
      mediator: invoiceForm.mediator,
      ppaCompany: invoiceForm.ppaCompany,
    });
  }

  mergeInvoices(invoiceIds: number[]): Observable<Invoice> {
    return this.http.post<Invoice>(`${this.apiConfig.baseUrl}/${this.path}/merge`, invoiceIds);
  }

  unMergeInvoices(invoice: Invoice, invoiceIds: number[]): Observable<Invoice> {
    return this.http.post<Invoice>(`${this.apiConfig.baseUrl}/${this.path}/${invoice.id}/unmerge`, invoiceIds);
  }

  getUnMergableInvoices(invoice: Invoice): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${this.apiConfig.baseUrl}/${this.path}/${invoice.id}/merged_list`);
  }

  downloadInvoice(invoice: Invoice): Observable<any> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${invoice.id}/download`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  sendInvoice(invoice: Invoice, data: any) {
    return this.http.post<Invoice>(`${this.apiConfig.baseUrl}/${this.path}/${invoice.id}/send`, data);
  }

  previewPdf(invoice: Invoice): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiConfig.baseUrl}/${this.path}/${invoice.id}/preview`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
