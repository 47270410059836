import { Component, Input } from '@angular/core';
import { Modal } from '@ppa/layout';
import { Dossier, Relation, RelationType } from '@ppa/data';

@Component({
  selector: 'ppa-select-dossier-modal',
  templateUrl: './select-dossier-modal.component.html',
  styleUrls: ['./select-dossier-modal.component.scss'],
})
export class SelectDossierModalComponent extends Modal {
  nested = false;
  relationType = null;
  showAddRelation = false;
  hideAddRelation = false;
  hideBlocked = false;
  @Input() routePoints: number[] = [];
  @Input() availableRoutePoints = 0;
  @Input() maxRoutePoints = 10;

  readonly relationTypeEnum = RelationType;

  handleDossierSelected(dossier: Dossier) {
    this.save(dossier, false);
  }
}
