import { Injectable } from '@angular/core';
import {
  CancelContract,
  Contract,
  ContractDataService,
  ContractTemplate,
  ContractTemplateDataService,
  ContractTemplateField,
  KeyValuePairs,
  PaginationOptions,
  PriceType,
  Product,
  Relation,
  VatCode,
} from '@ppa/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(
    private contractDataService: ContractDataService,
    private contractTemplateDataService: ContractTemplateDataService,
  ) {}

  getContractsForOverview(options: PaginationOptions) {
    return this.contractDataService.listPaged(options);
  }

  getContract(id: number) {
    return this.contractDataService.read(id);
  }

  saveContract(contract: Contract) {
    contract = this.contractTransformer(contract);
    return this.contractDataService.create(contract);
  }

  downloadContract(contract: Contract) {
    return this.contractDataService.downloadContract(contract);
  }

  updateContract(contract: Contract) {
    contract = this.contractTransformer(contract);
    return this.contractDataService.update(contract);
  }

  updateOwnReference(contract: Partial<Contract>) {
    return this.contractDataService.updateOwnReference(contract);
  }

  duplicate(contract: Contract, original_id: number) {
    contract = this.contractTransformer(contract);
    return this.contractDataService.duplicate(contract, original_id);
  }

  sendContract(contract: Contract, messages: { message: string }[]) {
    return this.contractDataService.send(contract, messages);
  }

  cancelContract(contract: Contract, data: string) {
    return this.contractDataService.cancel(contract, data);
  }

  getContractTemplates() {
    return this.contractTemplateDataService.list();
  }

  getDeliveryConditionOptions(): KeyValuePairs<string> {
    return [
      {
        key: 'modules.contract.create.delivery_condition_options.free',
        value: 'free',
      },
      {
        key: 'modules.contract.create.delivery_condition_options.off_company',
        value: 'off_company',
      },
      {
        key: 'modules.contract.create.delivery_condition_options.on_car',
        value: 'on_car',
      },
      {
        key: 'modules.contract.create.delivery_condition_options.in_boxes_on_car',
        value: 'in_boxes_on_car',
      },
      {
        key: 'modules.contract.create.delivery_condition_options.in_bigbags_on_car',
        value: 'in_bigbags_on_car',
      },
    ];
  }

  getToStorageOptions(): KeyValuePairs<string> {
    return [
      {
        key: 'Dronten',
        value: 'Dronten',
      },
      {
        key: 'Nieuwe-Tonge',
        value: 'Nieuwe-Tonge',
      },
    ];
  }

  getOnionClassificationOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.onion_classification_options.class_1', value: 'class_1' },
      { key: 'modules.contract.create.onion_classification_options.class_2', value: 'class_2' },
      { key: 'modules.contract.create.onion_classification_options.industry', value: 'industry' },
      { key: 'modules.contract.create.onion_classification_options.nakt', value: 'nakt' },
      { key: 'modules.contract.create.onion_classification_options.na', value: 'n/a' },
    ];
  }

  getTareLocationOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.tare_location_options.buyer', value: 'buyer' },
      { key: 'modules.contract.create.tare_location_options.seller', value: 'seller' },
      { key: 'modules.contract.create.tare_location_options.n/a', value: 'n/a' },
    ];
  }

  getWeighingCostsOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.weighing_costs_options.buyer', value: 'buyer' },
      { key: 'modules.contract.create.weighing_costs_options.seller', value: 'seller' },
    ];
  }

  getSproutInhibitorOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.sprout_inhibitor_options.yes', value: 'yes' },
      { key: 'modules.contract.create.sprout_inhibitor_options.no', value: 'no' },
      { key: 'modules.contract.create.sprout_inhibitor_options.in_consultation', value: 'in_consultation' },
    ];
  }

  getPriceTypeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.price_type_options.fixed', value: PriceType.Fixed },
      { key: 'modules.contract.create.price_type_options.agreement', value: PriceType.Agreement },
    ];
  }

  getCultivationOptions(): KeyValuePairs<string> {
    return [
      { key: 'TTW', value: 'TTW' },
      { key: 'Chicogrow', value: 'Chicogrow' },
      { key: 'Anders, namelijk:', value: 'OTHER' },
    ];
  }

  getCultivationPaidByOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.cultivation_paid_by_options.buyer', value: 'buyer' },
      { key: 'modules.contract.create.cultivation_paid_by_options.seller', value: 'seller' },
    ];
  }

  getCommissionPaidByOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.commission_paid_by_options.buyer', value: 'buyer' },
      { key: 'modules.contract.create.commission_paid_by_options.seller', value: 'seller' },
    ];
  }

  getTareByOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.final_bill_drawn_up_by_options.buyer', value: 'buyer' },
      { key: 'modules.contract.create.final_bill_drawn_up_by_options.seller', value: 'seller' },
      { key: 'modules.contract.create.final_bill_drawn_up_by_options.ppa', value: 'ppa' },
    ];
  }

  getLoadingMethodOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.loading_method_options.own_bin', value: 'own_bin' },
      { key: 'modules.contract.create.loading_method_options.bin_ppa', value: 'bin_ppa' },
      { key: 'modules.contract.create.loading_method_options.shovel', value: 'shovel' },
    ];
  }

  getBackBreedingOptions(): KeyValuePairs<string> {
    return [
      { key: '50 cm', value: '50 cm' },
      { key: '60 cm', value: '60 cm' },
      { key: '75 cm', value: '75 cm' },
    ];
  }

  getFinalBillDrawnUpByOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.final_bill_drawn_up_by_options.buyer', value: 'buyer' },
      { key: 'modules.contract.create.final_bill_drawn_up_by_options.seller', value: 'seller' },
      { key: 'modules.contract.create.final_bill_drawn_up_by_options.ppa', value: 'ppa' },
    ];
  }

  getAmountTypeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.contract.create.amount_type_options.yield', value: 'yield' },
      { key: 'modules.contract.create.amount_type_options.exact', value: 'exact' },
      { key: 'modules.contract.create.amount_type_options.estimate', value: 'estimate' },
    ];
  }

  private contractTransformer(contract: Contract): Contract {
    return {
      ...contract,
      ...(contract.price && { price: contract.price.toString().replace(',', '.') }),
      ...(contract.commission && { commission: contract.commission.toString().replace(',', '.') }),
    };
  }

  get getCancelReasonOptions(): KeyValuePairs<string> {
    return [
      {
        key: 'modules.contract.cancel.modal.reason_options.different_agreement',
        value: 'different_agreement',
      },
      {
        key: 'modules.contract.cancel.modal.reason_options.different_name',
        value: 'different_name',
      },
      {
        key: 'modules.contract.cancel.modal.reason_options.not_billable',
        value: 'not_billable',
      },
      {
        key: 'modules.contract.cancel.modal.reason_options.weather_conditions',
        value: 'weather_conditions',
      },
      {
        key: 'modules.contract.cancel.modal.reason_options.' + CancelContract.Other,
        value: CancelContract.Other,
      },
    ];
  }

  getContractFields(
    template: ContractTemplate,
    relation: Relation,
    product: Product,
  ): Observable<ContractTemplateField[]> {
    return this.contractTemplateDataService.getFields(template, relation, product);
  }
}
