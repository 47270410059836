<div class="relation-list__wrapper">
  <div>
    <form [formGroup]="searchForm">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'modules.relations.picker.search' | transloco }}</mat-label>
        <input matInput type="search" autocomplete="off" formControlName="term" />
        <i matSuffix class="uil uil-search"></i>
      </mat-form-field>
    </form>
  </div>

  <div class="relation-list">
    <ng-container *ngIf="dossiers$ | async as dossiers; else loading">
      <ng-container *ngIf="dossiers?.length; else noResult">
        <ng-container *ngFor="let dossier of dossiers">
          <div
            class="relation"
            [class.routePoint]="!isNotARoutePount(dossier) || !dossierIsNotSelected(dossier)"
            (click)="handleSelectDossier(dossier)"
          >
            <div class="title">
              {{ dossier.relation?.companyName + (dossier.title ? ' - ' + dossier.title : '') }}
              <span class="distance" *ngIf="dossier.$distance | async as distance">{{ distance }}</span>
            </div>
            <div class="info">
              {{ dossier.locationLine || dossier.relation?.addressLine }}

              <span *ngIf="isNotARoutePount(dossier) && dossierIsNotSelected(dossier)" class="next"
                ><i class="uil uil-arrow-right"></i
              ></span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #loading>
    <ppa-loading *ngIf="showLoader"></ppa-loading>
  </ng-template>

  <ng-template #noResult>
    <ppa-no-result></ppa-no-result>
  </ng-template>
</div>
