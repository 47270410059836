import { ConfirmDialogComponent, Intention, ModalService, PPAToastrService } from '@ppa/layout';
import { Component, HostBinding, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NotificationService, VersionService, Notification } from '@ppa/data';
import { throttleTime } from 'rxjs/operators';
import { translate } from '@ngneat/transloco';
import { LocationService } from './services/location.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastRef } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'ppa-root',
  template: `
    <router-outlet></router-outlet>
    <div #dynamic></div>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class.mobile') isMobile = false;
  @HostBinding('class.tablet') isTablet = false;
  @HostBinding('class.desktop') isDesktop = false;

  @ViewChild('dynamic', { read: ViewContainerRef, static: true })
  modalContainer: ViewContainerRef;
  confirmReloadModal;
  public coordinates$ = new BehaviorSubject<any>(null);

  constructor(
    protected modalService: ModalService,
    private deviceDetectorService: DeviceDetectorService,
    private versionService: VersionService,
    private notificationService: NotificationService,
    private locationService: LocationService,
    private toastrService: PPAToastrService,
    private router: Router,
  ) {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.isTablet = this.deviceDetectorService.isTablet();
    this.locationService.setCurrentLocation();
    this.coordinates$ = this.locationService.getCurrentLocationObserver();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.notificationService.startCheckingNotifications(true);
      }
    });
  }

  ngOnInit() {
    this.modalService.setModalContainerRef(this.modalContainer);
    this.versionService.upToDate$.pipe(throttleTime(5 * 60 * 1000)).subscribe((upToDate) => {
      if (!upToDate && !this.confirmReloadModal) {
        this.confirmReloadModal = this.modalService.createModal(ConfirmDialogComponent, {
          message: this.translation('global.version.title', 'PPAUI heeft een update gehad!'),
          information: this.translation(
            'global.version.message',
            'Om gebruik volledig gebruik te kunnen maken van alle mogelijkheden, ' +
              'dient de app ververst te worden! Wilt u doorgaan met verversen?<br />' +
              '<br /><strong>LET OP! Niet opgeslagen gegevens gaan verloren!!</strong>',
          ),
          confirmButtonText: this.translation('global.version.confirm', 'Opnieuw laden'),
          buttonColor: 'primary',
          cancelButtonColor: 'warn',
        });

        this.confirmReloadModal.dialog.onSave().then(() => {
          window.location.reload();
        });

        this.confirmReloadModal.dialog.onClose().then(() => {
          delete this.confirmReloadModal;
        });
      }
    });
    this.versionService.startCheckingVersion();

    this.notificationService.clearOpenNotifications();
    this.notificationService.notifications$.subscribe((notifications) => {
      for (const notification of notifications) {
        if (notification.is_read === false && notification.deleted === false) {
          if (this.notificationService.isNotOpen(notification)) {
            let toastr: ToastRef<any>;
            const closeAction$ = new Subject<void>();
            closeAction$.subscribe(() => {
              this.notificationService.readNotification(notification);
              this.notificationService.closeNotification(notification);
              toastr.close();
            });

            const openRouteAction$ = new Subject<void>();
            openRouteAction$.subscribe(() => {
              this.notificationService.readNotification(notification);
              this.notificationService.closeNotification(notification);
              toastr.close();
              this.router.navigateByUrl(notification.route);
            });

            this.notificationService.openNotification(notification);

            toastr = this.toastrService.displayToastr({
              icon: 'uil-info-circle',
              title: notification.title,
              messages: [notification.description],
              intention: Intention.Info,
              tapToDismiss: false,
              closeButton: false,
              buttons: [
                {
                  text: this.translation('modules.notification.close', 'Negeren'),
                  sub: closeAction$,
                },
                ...(notification.route
                  ? [
                      {
                        text: this.translation('modules.notification.view', 'Bekijken'),
                        sub: openRouteAction$,
                      },
                    ]
                  : []),
              ],
            });
          }
        }
      }
    });
    this.notificationService.startCheckingNotifications();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.locationService.setCurrentLocation();
    this.versionService.startCheckingVersion();
    this.notificationService.startCheckingNotifications();
  }

  translation(key: string, fallback: string): string {
    const translation = translate(key);

    if (fallback && translation === key) {
      return fallback;
    }

    return translation;
  }
}
