import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';
import { Notification } from '../contracts/notification';
import { NotificationDataService } from './notification-data.service';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKey } from '../contracts/local-storage-key';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private interval: any;
  private loaded = false;
  @Output() notifications$ = new Subject<Notification[]>();

  constructor(
    protected http: HttpClient,
    private authService: AuthService,
    private notificationDataService: NotificationDataService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
  ) {}

  checkNotifications() {
    if (this.authService.isLoggedIn()) {
      this.notificationDataService.notifications().subscribe((userNotifications) => {
        const notifications: Notification[] = [];

        for (const notification of userNotifications) {
          this.addNotificationToLocalStorage(this.notificationDataService.isNotified(notification));
        }

        this.notifications$.next(this.getNotificationsFromLocalStorage());
      });
    }
  }

  startCheckingNotifications(resetLoaded = false) {
    clearInterval(this.interval);
    if (resetLoaded || !this.loaded) {
      this.checkNotifications();
      this.loaded = true;
    }
    this.interval = setInterval(() => {
      this.checkNotifications();
    }, 30000);
  }

  addNotificationToLocalStorage(notification: Notification) {
    if (!this.localStorage.exists(LocalStorageKey.Notifications)) {
      this.localStorage.setItem(LocalStorageKey.Notifications, {});
    }

    const notifications = this.localStorage.getItem(LocalStorageKey.Notifications);
    notifications[notification.id] = notification;
    this.localStorage.setItem(LocalStorageKey.Notifications, notifications);
  }

  readNotification(notification: Notification) {
    const notifications = this.localStorage.getItem(LocalStorageKey.Notifications);
    notifications[notification.id] = this.notificationDataService.isRead(notification);
    this.localStorage.setItem(LocalStorageKey.Notifications, notifications);
  }

  getNotificationsFromLocalStorage(): Notification[] {
    const notifications = [];
    const storedNotifications = this.localStorage.getItem(LocalStorageKey.Notifications);
    const keys = Object.keys(storedNotifications);

    for (const key of keys) {
      notifications.push(storedNotifications[key]);
    }

    return notifications;
  }

  isNotOpen(notification: Notification): boolean {
    let notOpen = true;

    const openNotifications = this.sessionStorage.getItem(LocalStorageKey.Notifications);
    if (openNotifications && openNotifications[notification.id]) {
      notOpen = false;
    }

    return notOpen;
  }

  openNotification(notification: Notification) {
    if (!this.sessionStorage.exists(LocalStorageKey.Notifications)) {
      this.sessionStorage.setItem(LocalStorageKey.Notifications, {});
    }

    const openNotifications = this.sessionStorage.getItem(LocalStorageKey.Notifications);
    if (openNotifications) {
      openNotifications[notification.id] = true;
    }
    this.sessionStorage.setItem(LocalStorageKey.Notifications, openNotifications);
  }

  closeNotification(notification: Notification) {
    const openNotifications = this.sessionStorage.getItem(LocalStorageKey.Notifications);
    if (openNotifications && openNotifications[notification.id]) {
      delete openNotifications[notification.id];
    }
  }

  clearOpenNotifications(): void {
    this.sessionStorage.setItem(LocalStorageKey.Notifications, {});
  }
}
