import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { Notification } from '../contracts/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationDataService extends BaseService<Notification> {
  path = 'notifications';

  notifications(): Observable<ListResponse<Notification>> {
    return this.http.get<ListResponse<Notification>>(`${this.apiConfig.baseUrl}/notifications/`);
  }

  isNotified(notification: Notification): Notification {
    if (notification.notified !== true) {
      notification.notified = true;
      this.update(notification).subscribe((response) => {});
    }
    return notification;
  }

  isRead(notification: Notification): Notification {
    if (notification.is_read !== true) {
      notification.is_read = true;
      this.update(notification).subscribe((response) => {});
    }
    return notification;
  }
}
