import { LocalStorageKey } from './../contracts/local-storage-key';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  setItem<T>(key: LocalStorageKey, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: LocalStorageKey) {
    return <T>JSON.parse(sessionStorage.getItem(key));
  }

  removeItem(key: LocalStorageKey) {
    sessionStorage.removeItem(key);
  }

  exists(key: LocalStorageKey) {
    return sessionStorage.getItem(key) !== null;
  }
}
