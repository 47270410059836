<div [ngClass]="menuOpen$ | async">
  <div
    class="inner"
    [ngClass]="{
      error: config.intention === 'ERROR',
      info: config.intention === 'INFO',
      success: config.intention === 'SUCCESS',
      'has-button': config.buttons
    }"
  >
    <div class="toastr-icon">
      <i class="uil {{ config.icon }}"></i>
    </div>

    <div [attr.data-cy]="config.identifier" class="title">
      <h1>
        {{ config.title }}
      </h1>
    </div>

    <div class="messages" *ngIf="config.messages?.length">
      <span *ngFor="let message of config.messages"> {{ message }}&nbsp; </span>
    </div>

    <div class="buttons" *ngIf="config?.buttons">
      <button mat-flat-button *ngFor="let button of config.buttons; let i = index" (click)="handleClick(i)">
        {{ button.text }}
      </button>
    </div>

    <i *ngIf="config.closeButton === undefined || config.closeButton === true" class="uil uil-times toast-close"></i>
  </div>
</div>
