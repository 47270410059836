<ngx-datatable
  class="material"
  [rows]="rows"
  [columns]="columns"
  [columnMode]="ColumnMode.force"
  [headerHeight]="67"
  [footerHeight]="50"
  rowHeight="auto"
  [externalPaging]="true"
  [externalSorting]="true"
  [count]="metaData.totalItems"
  [offset]="metaData.page"
  [scrollbarH]="true"
  [limit]="metaData.perPage"
  [sorts]="sorts && [sorts]"
  (page)="setPage($event)"
  (sort)="onSort($event)"
  [scrollbarH]="horizontalScrollBar"
  (activate)="onActivate($event)"
></ngx-datatable>

<ng-template #translateTemplate let-row="row" let-value="value" let-column="column">
  <ng-container *ngIf="value !== ''">
    {{ column.translationPrefix + '.' + value | transloco }}
  </ng-container>
</ng-template>

<ng-template #invoiceTypeTemplate let-row="row" let-value="value" let-column="column">
  <span [class]="'invoice-type--' + (value || 'debit')"
    ><i class="uil uil-invoice"></i> {{ column.translationPrefix + '.' + (value || 'debit') | transloco }}</span
  >
</ng-template>

<ng-template #currencyTemplate let-row="row" let-value="value">
  {{ value | ppaCurrency: 'EUR' }}
</ng-template>

<ng-template #numberTemplate let-row="row" let-value="value">
  {{ value | ppaNumber: '1.0' }}
</ng-template>

<ng-template #actionTemplate let-column="column" let-value="value" let-row="row">
  <ng-container *ngIf="(!forceActionDots && column.actions?.length > 1) || forceActionDots">
    <button mat-button [matMenuTriggerFor]="menu"><i class="uil uil-ellipsis-v"></i></button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let action of column.actions">
        <ng-container *ngIf="showOnConditionTrue(action, row)">
          <button mat-menu-item (click)="action.callback(row)">
            <ng-container *ngIf="action.translateLabel">{{ action.label | transloco }}</ng-container>
            <ng-container *ngIf="!action.translateLabel">{{ action.label }}</ng-container>
          </button>
        </ng-container>
      </ng-container>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="!forceActionDots && column.actions?.length < 2">
    <ng-container *ngFor="let action of column.actions">
      <ng-container *ngIf="showOnConditionTrue(action, row)">
        <button (click)="action.callback(row); blurButtons()" mat-flat-button>
          <ng-container *ngIf="action.translateLabel">{{ action.label | transloco }}</ng-container>
          <ng-container *ngIf="!action.translateLabel">{{ action.label }}</ng-container>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #phoneTemplate let-value="value">
  <a href="tel:{{ value }}">{{ value }}</a>
</ng-template>

<ng-template #mailTemplate let-value="value">
  <a href="mailto:{{ value }}">{{ value }}</a>
</ng-template>

<ng-template #datetimeTemplate let-value="value">
  {{ value | date: 'dd-MM-y HH:mm:ss' }}
</ng-template>

<ng-template #dateTemplate let-value="value">
  {{ value | date: 'dd-MM-y' }}
</ng-template>

<ng-template #weekTemplate let-value="value">
  {{ value ? (value | ppaDate: 'W') : '' }}
</ng-template>

<ng-template #headerTemplate let-column="column">
  <span [innerHTML]="column.name | transloco | newLine"></span>
</ng-template>

<ng-template #postfixTemplate let-row="row" let-value="value" let-column="column">
  <ng-container *ngIf="column.numberFormat">
    {{ value | ppaNumber: column.numberFormat }}
  </ng-container>
  <ng-container *ngIf="!column.numberFormat">
    {{ value }}
  </ng-container>
  <ng-container *ngIf="value">{{ column.postfix }}</ng-container>
</ng-template>

<ng-template #postfixColumnTemplate let-row="row" let-value="value" let-column="column">
  {{ value }} {{ row[column.postfixColumn] }}
</ng-template>

<ng-template #booleanTemplate let-row="row" let-value="value" let-column="column">
  {{ 'data_table.boolean.' + this.getBoolean(value) | transloco }}
</ng-template>

<ng-template #fromObject let-row="row" let-value="value" let-column="column">
  {{ getItemFromObject(value, column.fromObject) | transloco }}
</ng-template>
