<div class="ppa-modal" [class.ppa-modal--nested]="nested">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h2>{{ 'modules.dossier.modal.select.title' | transloco }}</h2>
  </div>

  <ng-container>
    <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
      <ppa-select-dossier
        [hideBlocked]="hideBlocked"
        [routePoints]="routePoints"
        [maxRoutePoints]="maxRoutePoints"
        [availableRoutePoints]="availableRoutePoints"
        (selectDossier)="handleDossierSelected($event)"
      ></ppa-select-dossier>
    </div>
  </ng-container>
</div>

<div class="ppa-modal__fader" [class.ppa-modal__fader--nested]="nested"></div>
