import { Injectable } from '@angular/core';
import {
  ListResponse,
  PaginatedResponse,
  PaginationOptions,
  Relation,
  RelationDataService,
  RelationSummary,
  RelationType,
} from '@ppa/data';
import { Observable, race } from 'rxjs';
import { SelectRelationModalComponent } from '../../relation-picker/modals/select-relation-modal/select-relation-modal.component';
import { ModalService } from '@ppa/layout';
@Injectable({
  providedIn: 'root',
})
export class RelationService {
  constructor(private modalService: ModalService, private relationDataService: RelationDataService) {}

  getPaginatedRelations(options: PaginationOptions): Observable<PaginatedResponse<Relation>> {
    return this.relationDataService.listPaged(options);
  }

  // Open relation modal, returns selected Relation.
  selectRelation(
    nested: boolean = false,
    relationType?: RelationType,
    hideAddRelation?: boolean,
    hideBlocked?: boolean,
  ): Observable<Relation | boolean | void> {
    if (relationType === undefined) {
      relationType = null;
    }
    const modal = this.modalService.createModal(SelectRelationModalComponent, {
      nested,
      relationType,
      hideAddRelation,
      hideBlocked,
    });

    return race<Relation | boolean | void>([modal.dialog.onSaveObservable(), modal.dialog.onCloseObservable()]);
  }

  getRelations() {
    return this.relationDataService.list();
  }

  createRelation(relation: Relation) {
    return this.relationDataService.create(relation);
  }

  updateRelation(relation: Relation) {
    return this.relationDataService.update(relation);
  }

  getRelation(id: number): Observable<Relation> {
    return this.relationDataService.read(id);
  }

  getRelationSummary(id: number): Observable<RelationSummary> {
    return this.relationDataService.getSummary(id);
  }

  afasRelationCheck(id: number): Observable<{ afasRelation: boolean }> {
    return this.relationDataService.afasRelationCheck(id);
  }

  exportNewsletter() {
    return this.relationDataService.exportNewsletter();
  }

  getLogo(relation: Relation): Observable<any> {
    return this.relationDataService.getLogo(relation);
  }

  getAdministrations(): Observable<ListResponse<Relation>> {
    return this.relationDataService.getPPACompanies();
  }
}
