import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { ReadResponse } from '../contracts/read-response';
import { UserRoute } from '../contracts/user-route';

@Injectable({
  providedIn: 'root',
})
export class UserRouteService extends BaseService<UserRoute> {
  path = 'user-routes';

  routes(module: UserRoute): Observable<UserRoute[]> {
    return this.http.get<UserRoute[]>(`${this.apiConfig.baseUrl}/${this.path}/${module}`);
  }

  createRoute(data: Partial<UserRoute>): Observable<UserRoute> {
    return this.http.post<ReadResponse<UserRoute>>(`${this.apiConfig.baseUrl}/${this.path}`, data);
  }

  updateRoute(data: Partial<UserRoute>): Observable<UserRoute> {
    return this.http.put<ReadResponse<UserRoute>>(`${this.apiConfig.baseUrl}/${this.path}/${data.id}`, data);
  }

  deactivateRoute(userRouteId: number): Observable<boolean> {
    return this.http.post<ReadResponse<boolean>>(`${this.apiConfig.baseUrl}/${this.path}/deactivate/${userRouteId}`, {
      active: false,
    });
  }
}
