<ng-container *transloco="let t">
  <ppa-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"></ppa-breadcrumbs>

  <ng-container *ngIf="{ isAfasRelation: (afasRelationCheck$ | async)?.afasRelation } as result">
    <div class="form-error" *ngIf="!result.isAfasRelation">
      {{ 'modules.contract.create.relation_not_in_afas' | transloco }}
    </div>

    <div class="heading">
      <h4 class="subheading">{{ t('modules.contract.create.intro') }}</h4>
    </div>

    <ng-container *ngIf="isSentContract">
      <div class="form-warning form-warning--skip-before">
        {{ 'modules.contract.create.sent_contract_warning' | transloco }}
      </div>
    </ng-container>

    <form #form="ngForm" [formGroup]="contractForm" (ngSubmit)="handleSubmit()" autocomplete="off" class="pb-1">
      <!-- ALGEMEEN -->
      <div class="form-section">
        <h3>{{ t('modules.contract.create.sections.common') }}</h3>

        <mat-form-field appearance="fill">
          <mat-label>
            {{ t('modules.contract.create.reference_number_label') }}
            <span class="required" *ngIf="isRequiredField('referenceNumber') | async">*</span>
          </mat-label>
          <input matInput formControlName="referenceNumber" data-cy="contract-form-reference-number" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>
            {{ t('modules.contract.create.own_numbering_for_relation') }}
            <span class="required" *ngIf="isRequiredField('ownNumberingForRelation') | async">*</span>
          </mat-label>
          <input
            matInput
            formControlName="ownNumberingForRelation"
            data-cy="contract-form-own-numbering-for-relation"
          />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>
            {{ 'modules.contract.create.contract_date_label' | transloco }}
            <span class="required" *ngIf="isRequiredField('contractDate') | async">*</span>
          </mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="contractDate"
            readonly
            data-cy="contract-form-contract-date"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
        <div *ngIf="(form.submitted || controls.contractDate.touched) && controls.contractDate.invalid">
          <div class="form-error" *ngIf="controls.contractDate.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>

        <div class="flex-1-1">
          <ppa-select
            [items]="mediators$ | async"
            bindLabel="label"
            label="modules.contract.create.mediator_label"
            formControlName="mediator"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.mediator.touched) && controls.mediator.invalid">
            <div class="form-error" *ngIf="controls.mediator.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>

        <div class="flex-1-1">
          <ppa-select
            [items]="finalBillDrawnUpByOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.final_bill_drawn_up_by_label"
            formControlName="finalBillDrawnUpBy"
            data-cy="contract-form-final-bill-drawn-up-by"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.finalBillDrawnUpBy.touched) && controls.finalBillDrawnUpBy.invalid">
            <div class="form-error" *ngIf="controls.finalBillDrawnUpBy.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="(commissionContract$ | async) === false">
          <ppa-action-card actionIcon="uil-pen" (actionClicked)="openRelationModal()" [addMarginBottom]="true">
            <ng-container *ngIf="currentRelation$ | async as currentRelation; else relationPlaceholder">
              <div class="relation-label">{{ 'modules.contract.create.relation_label' | transloco }}</div>
              <div class="relation-company">{{ currentRelation.companyName }}</div>
              <div class="relation-address">{{ currentRelation.addressLine }}</div>
            </ng-container>
            <ng-template #relationPlaceholder>
              <div class="relation-placeholder">{{ 'modules.contract.create.relation_label' | transloco }}</div>
            </ng-template>
          </ppa-action-card>
        </ng-container>
      </div>

      <!-- POOL -->
      <div class="form-section" *ngIf="displayPool">
        <h3>{{ t('modules.contract.create.sections.pool') }}</h3>

        <mat-form-field appearance="fill">
          <mat-label>
            {{ t('modules.contract.create.pool_label') }}
            <span class="required" *ngIf="isRequiredField('poolName') | async">*</span>
          </mat-label>
          <input matInput formControlName="poolName" data-cy="pool-name" />
        </mat-form-field>

        <ng-container *ngIf="displayPoolFinalPaymentDate && contractConditionalFields.has('poolFinalPaymentDate')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ 'modules.order.create.pool_finaly_payment_date_label' | transloco }}
              <span class="required" *ngIf="isRequiredField('poolFinalPaymentDate') | async">*</span>
            </mat-label>
            <input
              matInput
              [matDatepicker]="picker2"
              formControlName="poolFinalPaymentDate"
              readonly
              data-cy="contract-form-pool-final-payment-date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
          </mat-form-field>
          <div
            *ngIf="(form.submitted || controls.poolFinalPaymentDate.touched) && controls.poolFinalPaymentDate.invalid"
          >
            <div class="form-error" *ngIf="controls.poolFinalPaymentDate.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- COMMISSION -->
      <div class="form-section" *ngIf="(commissionContract$ | async) === true">
        <h3>{{ t('modules.contract.create.sections.commission') }}</h3>

        <ng-container>
          <div class="flex-row">
            <div class="flex-1-1">
              <mat-slide-toggle formControlName="commissionPercentage">
                {{ t('modules.contract.create.commission_percentage_label') }}
                <span class="required" *ngIf="isRequiredField('commissionPercentage') | async">*</span>
              </mat-slide-toggle>
            </div>

            <div class="flex-1-2">
              <mat-form-field appearance="fill">
                <mat-label>
                  {{ t('modules.contract.create.commission_label') }}
                  <span class="required" *ngIf="isRequiredField('commission') | async">*</span>
                </mat-label>
                <input
                  type="tel"
                  inputmode="decimal"
                  matInput
                  ppaNumberInput
                  formControlName="commission"
                  data-cy="contract-form-commission"
                />
                <i *ngIf="controls.commissionPercentage.value" matSuffix class="uil uil-percentage"></i>
                <i *ngIf="!controls.commissionPercentage.value" matSuffix class="uil uil-euro"></i>
              </mat-form-field>
              <div *ngIf="(form.submitted || controls.commission.touched) && controls.commission.invalid">
                <div class="form-error" *ngIf="controls.commission.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
                <div class="form-error" *ngIf="controls.commission.errors.pattern">
                  <span>{{ 'form_errors.invalid_currency' | transloco }}</span>
                </div>
              </div>
            </div>
            <div class="flex-1-2">
              <ppa-select
                [items]="priceUnitOptions$ | async"
                bindLabel="localTitle"
                label="modules.contract.create.commission_price_unit_label"
                formControlName="commissionPriceUnit"
              ></ppa-select>
              <div
                *ngIf="(form.submitted || controls.commissionPriceUnit.touched) && controls.commissionPriceUnit.invalid"
              >
                <div class="form-error" *ngIf="controls.commissionPriceUnit.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-1-1">
            <ppa-select
              [items]="commissionPaidByOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.commission_paid_by_label"
              formControlName="commissionPaidBy"
              data-cy="contract-form-commission-paid-by"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.commissionPaidBy.touched) && controls.commissionPaidBy.invalid">
              <div class="form-error" *ngIf="controls.commissionPaidBy.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- CONTENT -->
      <div class="form-section">
        <h3>{{ t('modules.contract.create.sections.content') }}</h3>

        <ng-container *ngIf="contractConditionalFields.has('origin')">
          <ppa-select
            label="modules.contract.create.origin_label"
            formControlName="originList"
            data-cy="contract-form-origin"
            [originSelect]="true"
          ></ppa-select>
          <ng-container *ngIf="controls.originList.value === 'OTHER'">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.contract.create.origin_other_label') }}
                <span class="required" *ngIf="isRequiredField('origin') | async">*</span>
              </mat-label>
              <input matInput formControlName="origin" data-cy="contract-form-origin" />
            </mat-form-field>
          </ng-container>
          <div
            *ngIf="
              (form.submitted || controls.origin.touched || controls.originList.touched) &&
              (controls.origin.invalid || controls.originList.invalid)
            "
          >
            <div class="form-error" *ngIf="controls.origin.errors.required || controls.originList.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('harvestYear')">
          <ppa-select
            label="modules.dossier.create.harvest_year_label"
            formControlName="harvestYear"
            data-cy="contract-form-harvest-year"
            [yearSelect]="true"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.harvestYear.touched) && controls.harvestYear.invalid">
            <div class="form-error" *ngIf="controls.harvestYear.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.harvestYear.errors.pattern">
              <span>{{ 'modules.contract.create.form_errors.invalid_harvest_year' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('deliveryYear')">
          <ppa-select
            label="modules.contract.create.delivery_year_label"
            formControlName="deliveryYear"
            data-cy="contract-form-delivery-year"
            [yearSelect]="true"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.deliveryYear.touched) && controls.deliveryYear.invalid">
            <div class="form-error" *ngIf="controls.deliveryYear.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.deliveryYear.errors.pattern">
              <span>{{ 'modules.contract.create.form_errors.invalid_delivery_year' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            contractConditionalFields.has('amountOfUnit') ||
            contractConditionalFields.has('amountUnit') ||
            contractConditionalFields.has('amount')
          "
        >
          <ng-container>
            <ppa-select
              [items]="amountTypeOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.amount_type_label"
              formControlName="amountType"
              data-cy="contract-form-amount-type"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.amountType.touched) && controls.amountType.invalid">
              <div class="form-error" *ngIf="controls.amountType.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngTemplateOutlet="amountUnitSelect; context: { form: contractForm, order: order }"
          ></ng-container>
          <div class="flex-row">
            <div class="flex-1-1">
              <div class="amount__field">
                <mat-form-field appearance="fill">
                  <mat-label>
                    {{ t('modules.contract.create.amount_label') }}
                    <span class="required" *ngIf="isRequiredField('amount') | async">*</span>
                  </mat-label>
                  <input
                    type="tel"
                    inputmode="decimal"
                    matInput
                    ppaNumberInput
                    formControlName="amount"
                    data-cy="contract-form-amount"
                  />
                  <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                </mat-form-field>

                <button
                  type="button"
                  matSuffix
                  mat-mini-fab
                  matTooltip="{{ 'general.calculate_amount' | transloco }}"
                  aria-label="{{ 'general.calculate_amount' | transloco }}"
                  (click)="calculateAmount(true)"
                  class="amount__button"
                >
                  <i class="uil uil-calculator"></i>
                </button>
              </div>
              <div *ngIf="controls.amount.valid">
                <div class="form-info">
                  <span>{{ 'modules.contract.create.stays_internal' | transloco }}</span>
                </div>
              </div>
              <div *ngIf="(form.submitted || controls.amount.touched) && controls.amount.invalid">
                <div class="form-error" *ngIf="controls.amount.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
                <div class="form-error" *ngIf="controls.amount.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('yield') || contractConditionalFields.has('yieldUnit')">
          <div class="flex-row">
            <div class="flex-1-2">
              <mat-form-field appearance="fill">
                <mat-label
                  >{{ 'modules.contract.create.yield_label' | transloco }}
                  <span class="required" *ngIf="isRequiredField('yield') | async">*</span>
                </mat-label>
                <input
                  type="tel"
                  inputmode="decimal"
                  matInput
                  ppaNumberInput
                  formControlName="yield"
                  data-cy="contract-form-yield"
                />
              </mat-form-field>
              <div *ngIf="(form.submitted || controls.yield.touched) && controls.yield.invalid">
                <div class="form-error" *ngIf="controls.yield.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
                <div class="form-error" *ngIf="controls.yield.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
            <div class="flex-1-2">
              <ppa-select
                [items]="yieldUnitOptions$ | async"
                bindLabel="title"
                label="modules.contract.create.yield_unit"
                formControlName="yieldUnit"
              >
              </ppa-select>
              <div *ngIf="(form.submitted || controls.yieldUnit.touched) && controls.yieldUnit.invalid">
                <div class="form-error" *ngIf="controls.yieldUnit.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('price') || contractConditionalFields.has('priceAgreement')">
          <ppa-select
            [items]="priceTypeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.price_type_label"
            formControlName="priceType"
            data-cy="contract-form-price-type"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.priceType.touched) && controls.priceType.invalid">
            <div class="form-error" *ngIf="controls.priceType.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>

          <ng-container *ngIf="selectedPriceType$ | async as selectedPriceType">
            <ng-container *ngIf="selectedPriceType === priceType.Fixed">
              <div class="flex-row">
                <div class="flex-1-2">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.contract.create.price_label') }}
                      <span class="required" *ngIf="isRequiredField('price') | async">*</span>
                    </mat-label>
                    <input
                      type="tel"
                      inputmode="decimal"
                      matInput
                      ppaNumberInput
                      formControlName="price"
                      data-cy="contract-form-price"
                    />
                    <i matSuffix class="uil uil-euro"></i>
                  </mat-form-field>
                  <div *ngIf="(form.submitted || controls.price.touched) && controls.price.invalid">
                    <div class="form-error" *ngIf="controls.price.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                    <div class="form-error" *ngIf="controls.price.errors.pattern">
                      <span>{{ 'form_errors.invalid_currency' | transloco }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex-1-2">
                  <ppa-select
                    [items]="priceUnitOptions$ | async"
                    bindLabel="localTitle"
                    label="modules.contract.create.price_unit_label"
                    formControlName="priceUnit"
                  ></ppa-select>
                  <div *ngIf="(form.submitted || controls.priceUnit.touched) && controls.priceUnit.invalid">
                    <div class="form-error" *ngIf="controls.priceUnit.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="selectedPriceType === priceType.Agreement">
              <ppa-select
                [items]="priceTemplates$ | async"
                bindLabel="name"
                label="modules.contract.create.price_template_label"
                formControlName="priceTemplate"
                data-cy="contract-form-price-template"
              ></ppa-select>

              <div class="mb-1">
                <ngx-wig
                  buttons="bold,italic,underline,list1,list2"
                  placeholder="{{
                    t('modules.contract.create.price_agreement_label') +
                      ((isRequiredField('priceAgreement') | async) ? ' * ' : '')
                  }}"
                  formControlName="priceAgreement"
                ></ngx-wig>
              </div>
              <div *ngIf="(form.submitted || controls.priceAgreement.touched) && controls.priceAgreement.invalid">
                <div class="form-error" *ngIf="controls.priceAgreement.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('vatCode')">
          <ppa-select
            [items]="vatCodes$ | async"
            label="modules.contract.create.vat_label"
            bindLabel="description"
            formControlName="vatCode"
            data-cy="contract-form-vat"
            [compareFn]="vatSelectCompareFn"
          >
          </ppa-select>
          <div *ngIf="(form.submitted || controls.vatCode.touched) && controls.vatCode.invalid">
            <div class="form-error" *ngIf="controls.vatCode.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container #Teelt>
          <ng-container *ngIf="contractConditionalFields.has('seedsPaidBy')">
            <ppa-select
              [items]="cultivationPaidByOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.seeds_paid_by_label"
              formControlName="seedsPaidBy"
              data-cy="contract-form-seeds-paid-by"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.seedsPaidBy.touched) && controls.seedsPaidBy.invalid">
              <div class="form-error" *ngIf="controls.seedsPaidBy.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('extraSeedingUntil')">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ 'modules.contract.create.extra_seeding_until_label' | transloco }}
                <span class="required" *ngIf="isRequiredField('extraSeedingUntil') | async">*</span>
              </mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="extraSeedingUntil"
                readonly
                data-cy="contract-form-extra-seeding-until"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.extraSeedingUntil.touched) && controls.extraSeedingUntil.invalid">
              <div class="form-error" *ngIf="controls.extraSeedingUntil.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('extraSeedingPaidBy')">
            <ppa-select
              [items]="cultivationPaidByOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.extra_seeding_paid_by_label"
              formControlName="extraSeedingPaidBy"
              data-cy="contract-form-seeds-paid-by"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.extraSeedingPaidBy.touched) && controls.extraSeedingPaidBy.invalid">
              <div class="form-error" *ngIf="controls.extraSeedingPaidBy.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('mineralInvestigationPaidBy')">
            <ppa-select
              [items]="cultivationPaidByOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.mineral_investigation_paid_by_label"
              formControlName="mineralInvestigationPaidBy"
              data-cy="contract-form-mineral-investigation-paid-by"
            ></ppa-select>
            <div
              *ngIf="
                (form.submitted || controls.mineralInvestigationPaidBy.touched) &&
                controls.mineralInvestigationPaidBy.invalid
              "
            >
              <div class="form-error" *ngIf="controls.mineralInvestigationPaidBy.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('cultivationGuidance')">
            <ppa-select
              label="modules.contract.create.cultivation_guidance_label"
              formControlName="cultivationGuidanceList"
              data-cy="contract-form-cultivation-guidance"
              [items]="cultivationOptions"
              [translateLabel]="false"
              bindLabel="key"
              bindValue="value"
              [required]="isRequiredField('cultivationGuidance')"
            ></ppa-select>
            <ng-container *ngIf="controls.cultivationGuidanceList.value === 'OTHER'">
              <mat-form-field appearance="fill">
                <mat-label>
                  {{ t('modules.contract.create.cultivation_guidance_other_label') }}
                  <span class="required" *ngIf="isRequiredField('cultivationGuidance') | async">*</span>
                </mat-label>
                <input matInput formControlName="cultivationGuidance" data-cy="contract-form-origin" />
              </mat-form-field>
            </ng-container>
            <div
              *ngIf="
                (form.submitted || controls.cultivationGuidance.touched || controls.cultivationGuidanceList.touched) &&
                (controls.cultivationGuidance.invalid || controls.cultivationGuidanceList.invalid)
              "
            >
              <div
                class="form-error"
                *ngIf="controls.cultivationGuidance.errors.required || controls.cultivationGuidanceList.errors.required"
              >
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('cultivationGuidancePaidBy')">
            <ppa-select
              [items]="cultivationPaidByOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.cultivation_guidance_paid_by_label"
              formControlName="cultivationGuidancePaidBy"
              data-cy="contract-form-cultivation-guidance-paid-by"
            ></ppa-select>
            <div
              *ngIf="
                (form.submitted || controls.cultivationGuidancePaidBy.touched) &&
                controls.cultivationGuidancePaidBy.invalid
              "
            >
              <div class="form-error" *ngIf="controls.cultivationGuidancePaidBy.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="contractConditionalFields.has('minPlantsPerHa') && contractConditionalFields.has('maxPlantsPerHa')"
          >
            <div class="flex-row">
              <ng-container *ngIf="contractConditionalFields.has('minPlantsPerHa')">
                <div class="flex-1-2">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.contract.create.min_plants_per_ha_label') }}
                      <span class="required" *ngIf="isRequiredField('minPlantsPerHa') | async">*</span>
                    </mat-label>
                    <input matInput formControlName="minPlantsPerHa" data-cy="contract-form-min-length-root" />
                    <span matSuffix>{{ 'modules.contract.create.min_plants_per_ha_suffix' | transloco }}</span>
                  </mat-form-field>
                  <div *ngIf="(form.submitted || controls.minPlantsPerHa.touched) && controls.minPlantsPerHa.invalid">
                    <div class="form-error" *ngIf="controls.minPlantsPerHa.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                    <div class="form-error" *ngIf="controls.minPlantsPerHa.errors.pattern">
                      <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="contractConditionalFields.has('maxPlantsPerHa')">
                <div class="flex-1-2">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.contract.create.max_plants_per_ha_label') }}
                      <span class="required" *ngIf="isRequiredField('maxPlantsPerHa') | async">*</span>
                    </mat-label>
                    <input matInput formControlName="maxPlantsPerHa" data-cy="contract-form-max-length-root" />
                    <span matSuffix>{{ 'modules.contract.create.max_plants_per_ha_suffix' | transloco }}</span>
                  </mat-form-field>
                  <div *ngIf="(form.submitted || controls.maxPlantsPerHa.touched) && controls.maxPlantsPerHa.invalid">
                    <div class="form-error" *ngIf="controls.maxPlantsPerHa.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                    <div class="form-error" *ngIf="controls.maxPlantsPerHa.errors.pattern">
                      <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container
            *ngIf="contractConditionalFields.has('minLengthRoot') && contractConditionalFields.has('maxLengthRoot')"
          >
            <div class="flex-row">
              <ng-container *ngIf="contractConditionalFields.has('minLengthRoot')">
                <div class="flex-1-2">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.contract.create.min_length_root_label') }}
                      <span class="required" *ngIf="isRequiredField('minLengthRoot') | async">*</span>
                    </mat-label>
                    <input matInput formControlName="minLengthRoot" data-cy="contract-form-min-length-root" />
                    <span matSuffix>{{ 'modules.contract.create.min_length_root_suffix' | transloco }}</span>
                  </mat-form-field>
                  <div *ngIf="(form.submitted || controls.minLengthRoot.touched) && controls.minLengthRoot.invalid">
                    <div class="form-error" *ngIf="controls.minLengthRoot.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                    <div class="form-error" *ngIf="controls.minLengthRoot.errors.pattern">
                      <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="contractConditionalFields.has('maxLengthRoot')">
                <div class="flex-1-2">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.contract.create.max_length_root_label') }}
                      <span class="required" *ngIf="isRequiredField('maxLengthRoot') | async">*</span>
                    </mat-label>
                    <input matInput formControlName="maxLengthRoot" data-cy="contract-form-max-length-root" />
                    <span matSuffix>{{ 'modules.contract.create.max_length_root_suffix' | transloco }}</span>
                  </mat-form-field>
                  <div *ngIf="(form.submitted || controls.maxLengthRoot.touched) && controls.maxLengthRoot.invalid">
                    <div class="form-error" *ngIf="controls.maxLengthRoot.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                    <div class="form-error" *ngIf="controls.maxLengthRoot.errors.pattern">
                      <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container
            *ngIf="contractConditionalFields.has('minWidthRoot') && contractConditionalFields.has('maxWidthRoot')"
          >
            <div class="flex-row">
              <ng-container *ngIf="contractConditionalFields.has('minWidthRoot')">
                <div class="flex-1-2">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.contract.create.min_width_root_label') }}
                      <span class="required" *ngIf="isRequiredField('minWidthRoot') | async">*</span>
                    </mat-label>
                    <input matInput formControlName="minWidthRoot" data-cy="contract-form-min-length-root" />
                    <span matSuffix>{{ 'modules.contract.create.min_width_root_suffix' | transloco }}</span>
                  </mat-form-field>
                  <div *ngIf="(form.submitted || controls.minWidthRoot.touched) && controls.minWidthRoot.invalid">
                    <div class="form-error" *ngIf="controls.minWidthRoot.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                    <div class="form-error" *ngIf="controls.minWidthRoot.errors.pattern">
                      <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="contractConditionalFields.has('maxWidthRoot')">
                <div class="flex-1-2">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.contract.create.max_width_root_label') }}
                      <span class="required" *ngIf="isRequiredField('maxWidthRoot') | async">*</span>
                    </mat-label>
                    <input matInput formControlName="maxWidthRoot" data-cy="contract-form-max-length-root" />
                    <span matSuffix>{{ 'modules.contract.create.max_width_root_suffix' | transloco }}</span>
                  </mat-form-field>
                  <div *ngIf="(form.submitted || controls.maxWidthRoot.touched) && controls.maxWidthRoot.invalid">
                    <div class="form-error" *ngIf="controls.maxWidthRoot.errors.required">
                      <span>{{ 'form_errors.required' | transloco }}</span>
                    </div>
                    <div class="form-error" *ngIf="controls.maxWidthRoot.errors.pattern">
                      <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('targetHeadLength')">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ 'modules.contract.create.target_head_length_label' | transloco }}
                <span class="required" *ngIf="isRequiredField('targetHeadLength') | async">*</span>
              </mat-label>
              <input
                matInput
                formControlName="targetHeadLength"
                placeholder="bijv. 3-6cm"
                data-cy="contract-form-target-head-length"
              />
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.targetHeadLength.touched) && controls.targetHeadLength.invalid">
              <div class="form-error" *ngIf="controls.targetHeadLength.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('loadingMethod')">
            <ppa-select
              [items]="loadingMethodOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.loading_method_label"
              formControlName="loadingMethod"
              data-cy="contract-form-loading-method"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.loadingMethod.touched) && controls.loadingMethod.invalid">
              <div class="form-error" *ngIf="controls.loadingMethod.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="contractConditionalFields.has('backBreeding')">
            <ppa-select
              [items]="backBreedingOptions"
              [translateLabel]="false"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.back_breeding_label"
              formControlName="backBreeding"
              data-cy="contract-form-back-breeding"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.backBreeding.touched) && controls.backBreeding.invalid">
              <div class="form-error" *ngIf="controls.backBreeding.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('deliveryCondition')">
          <ppa-select
            [items]="deliveryOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.delivery_condition_label"
            formControlName="deliveryCondition"
            data-cy="contract-form-delivery-condition"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.deliveryCondition.touched) && controls.deliveryCondition.invalid">
            <div class="form-error" *ngIf="controls.deliveryCondition.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('deliveryPeriod')">
          <div class="flex-row">
            <div class="flex-1-1">
              <ppa-delivery-period formControlName="deliveryPeriod"></ppa-delivery-period>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('onionClassification')">
          <ppa-select
            [items]="onionClassificationOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.onion_classification_label"
            formControlName="onionClassification"
            data-cy="contract-form-onion-classification"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.onionClassification.touched) && controls.onionClassification.invalid">
            <div class="form-error" *ngIf="controls.onionClassification.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('minimumGrit')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.contract.create.minimum_grit_label') }}
              <span class="required" *ngIf="isRequiredField('minimumGrit') | async">*</span>
            </mat-label>
            <input matInput formControlName="minimumGrit" data-cy="contract-form-minimum-grit" />
            <span matSuffix>{{ 'modules.contract.create.minimum_grit_suffix' | transloco }}</span>
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.minimumGrit.touched) && controls.minimumGrit.invalid">
            <div class="form-error" *ngIf="controls.minimumGrit.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.minimumGrit.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('sizeSorting')">
          <ppa-select
            [items]="sizeSorting$ | async"
            [multiple]="true"
            bindLabel="name"
            bindValue="id"
            label="modules.dossier.create.size_sorting_label"
            formControlName="sizeSorting"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.sizeSorting.touched) && controls.sizeSorting.invalid">
            <div class="form-error" *ngIf="controls.sizeSorting.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('disinfected')">
          <mat-slide-toggle formControlName="disinfected">
            {{ t('modules.dossier.create.disinfected_label') }}
            <span class="required" *ngIf="isRequiredField('disinfected') | async">*</span>
          </mat-slide-toggle>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('treatment')">
          <ppa-select
            [items]="treatments$ | async"
            [multiple]="true"
            [clearable]="true"
            bindLabel="name"
            bindValue="id"
            label="modules.contract.create.treatment_label"
            formControlName="treatment"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.treatment.touched) && controls.treatment.invalid">
            <div class="form-error" *ngIf="controls.treatment.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('treatmentPaidBy')">
          <ppa-select
            [items]="cultivationPaidByOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.treatment_paid_by_label"
            formControlName="treatmentPaidBy"
            data-cy="contract-form-treatment-paid-by"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.treatmentPaidBy.touched) && controls.treatmentPaidBy.invalid">
            <div class="form-error" *ngIf="controls.treatmentPaidBy.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('sproutInhibitor')">
          <ppa-select
            [items]="sproutInhibitorOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.sprout_inhibitor_label"
            formControlName="sproutInhibitor"
            data-cy="contract-form-sprout-inhibitor"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.sproutInhibitor.touched) && controls.sproutInhibitor.invalid">
            <div class="form-error" *ngIf="controls.sproutInhibitor.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('taring')">
          <ppa-select
            [items]="tareTemplates$ | async"
            bindLabel="name"
            label="modules.contract.create.tare_template_label"
            formControlName="tareTemplate"
            data-cy="contract-form-tare-template"
          ></ppa-select>

          <div class="mb-1">
            <ngx-wig
              buttons="bold,italic,underline,list1,list2"
              placeholder="{{
                t('modules.contract.create.taring_label') + ((isRequiredField('taring`') | async) ? ' * ' : '')
              }}"
              formControlName="taring"
              data-cy="contract-form-taring"
            ></ngx-wig>
          </div>
          <div *ngIf="(form.submitted || controls.taring.touched) && controls.taring.invalid">
            <div class="form-error" *ngIf="controls.taring.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('tareBy')">
          <ppa-select
            [items]="tareByOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.tare_by_label"
            formControlName="tareBy"
            data-cy="contract-form-tare-by"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.tareBy.touched) && controls.tareBy.invalid">
            <div class="form-error" *ngIf="controls.tareBy.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('tareLocation')">
          <ppa-select
            [items]="tareLocationOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.tare_location_label"
            formControlName="tareLocation"
            data-cy="contract-form-tare-location"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.tareLocation.touched) && controls.tareLocation.invalid">
            <div class="form-error" *ngIf="controls.tareLocation.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('packaging')">
          <ppa-select
            [items]="packagingOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.packaging_label"
            formControlName="packaging"
          ></ppa-select>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('weighingCosts')">
          <ppa-select
            [items]="weighingCostsOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.contract.create.weighing_costs_label"
            formControlName="weighingCosts"
            data-cy="contract-form-weighing-costs"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.weighingCosts.touched) && controls.weighingCosts.invalid">
            <div class="form-error" *ngIf="controls.weighingCosts.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('termOfPayment')">
          <ng-container *ngIf="displayPoolFinalPaymentDate === false">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.contract.create.term_of_payment_label') }}
                <span class="required" *ngIf="isRequiredField('termOfPayment') | async">*</span>
              </mat-label>
              <input matInput formControlName="termOfPayment" data-cy="contract-form-term-of-payment" />
              <span matSuffix>{{ 'modules.contract.create.days' | transloco }}</span>
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.termOfPayment.touched) && controls.termOfPayment.invalid">
              <div class="form-error" *ngIf="controls.termOfPayment.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.termOfPayment.errors.pattern">
                <span>{{ 'modules.contract.create.form_errors.invalid_term_of_payment' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('notes')">
          <ppa-select
            [items]="noteTemplates$ | async"
            bindLabel="name"
            label="modules.contract.create.note_template_label"
            formControlName="noteTemplate"
            data-cy="contract-form-note-template"
          ></ppa-select>

          <div class="mb-1">
            <ngx-wig
              buttons="bold,italic,underline,list1,list2"
              placeholder="{{
                t('modules.contract.create.notes_label') + ((isRequiredField('notes') | async) ? ' * ' : '')
              }}"
              formControlName="notes"
              data-cy="contract-form-notes"
            ></ngx-wig>
          </div>
          <div *ngIf="(form.submitted || controls.notes.touched) && controls.notes.invalid">
            <div class="form-error" *ngIf="controls.notes.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('countryOfOrigin')">
          <div class="flex-1-1">
            <ppa-select
              [items]="countries$ | async"
              bindLabel="name"
              bindValue="name"
              label="modules.contract.create.country_of_origin_label"
              formControlName="countryOfOrigin"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.countryOfOrigin.touched) && controls.countryOfOrigin.invalid">
              <div class="form-error" *ngIf="controls.countryOfOrigin.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="contractConditionalFields.has('vatIncluded')">
          <mat-slide-toggle formControlName="vatIncluded" data-cy="contract-form-vat-included">
            {{ 'modules.contract.create.vat_included_label' | transloco }}
            <span class="required" *ngIf="isRequiredField('vatIncluded') | async">*</span>
          </mat-slide-toggle>
        </ng-container>

        <ng-container *ngIf="true || contractConditionalFields.has('toStorage')">
          <ng-container *ngIf="isPurchaseContract">
            <ppa-select
              [items]="toStorageOptions"
              bindLabel="key"
              bindValue="value"
              label="modules.contract.create.to_storage_label"
              formControlName="toStorage"
              data-cy="contract-form-to-storage"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.toStorage.touched) && controls.toStorage.invalid">
              <div class="form-error" *ngIf="controls.toStorage.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!-- CERTIFICATION -->
      <ng-container *ngIf="contractConditionalFields.has('certification')">
        <div class="form-section" *ngIf="relationCertificates$ | async as relationCertificates">
          <h3>{{ t('modules.contract.create.sections.certification') }}</h3>

          <ng-container>
            <ul
              class="list"
              data-cy="order-edit-certificates"
              formArrayName="certification"
              *ngIf="relationCertificates?.length > 0; else noCertificates"
            >
              <li
                class="list__item"
                *ngFor="let relationCertificate of relationCertificates; index as i"
                [formGroupName]="i"
              >
                <ppa-certificate-checkbox
                  [id]="relationCertificate.certificate.id"
                  [title]="relationCertificate.certificate.name"
                  [expirationDate]="relationCertificate.date"
                  [organic]="relationCertificate.certificate.organic"
                  [certificate]="relationCertificate"
                  formControlName="certificate"
                ></ppa-certificate-checkbox>
              </li>
            </ul>
            <ng-template #noCertificates>
              {{ 'certificate.no_certificates' | transloco }}
            </ng-template>
          </ng-container>
        </div>
      </ng-container>

      <!-- ATTACHMENTS -->
      <div class="form-section">
        <h3>{{ t('modules.contract.create.sections.attachments') }}</h3>

        <div>
          <mat-slide-toggle
            formControlName="attachCertificationInMail"
            data-cy="contract-form-attach-certification-in-mail"
          >
            {{ 'modules.contract.create.attach_certification_in_mail_label' | transloco }}
            <span class="required" *ngIf="isRequiredField('attachCertificationInMail') | async">*</span>
          </mat-slide-toggle>
        </div>

        <div>
          <mat-slide-toggle formControlName="attachConditionsInMail" data-cy="contract-form-attach-conditions-in-mail">
            {{ 'modules.contract.create.attach_conditions_in_mail_label' | transloco }}
            <span class="required" *ngIf="isRequiredField('attachConditionsInMail') | async">*</span>
          </mat-slide-toggle>
        </div>

        <ppa-file-upload
          formArrayName="attachments"
          [items]="attachments.controls"
          (handleAttachments)="onHandleAttachments($event)"
          [multiple]="true"
          [showHeading]="false"
        ></ppa-file-upload>
      </div>

      <button
        mat-raised-button
        class="button--full-width"
        color="primary"
        data-cy="button-contract-save"
        [disabled]="!result.isAfasRelation"
      >
        {{ t('modules.contract.buttons.save_contract') }}
      </button>
    </form>
  </ng-container>
</ng-container>

<ng-template #amountUnitSelect let-form="form" let-order="order">
  <div class="flex-row" [formGroup]="form">
    <div class="flex-1-2">
      <mat-form-field appearance="fill">
        <mat-label
          >{{ 'form.elements.unit.amount_of_unit_label' | transloco }}
          <span class="required" *ngIf="isRequiredField('amountOfUnit') | async">*</span>
        </mat-label>
        <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="amountOfUnit" />
      </mat-form-field>
      <div *ngIf="(form.submitted || controls.amountOfUnit.touched) && controls.amountOfUnit.invalid">
        <div class="form-error" *ngIf="controls.amountOfUnit.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
        <div class="form-error" *ngIf="controls.amountOfUnit.errors.pattern">
          <span>{{ 'form_errors.invalid_number' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="flex-1-2">
      <ppa-select
        [items]="amountUnitOptions$ | async"
        bindLabel="title"
        label="form.elements.unit.amount_unit"
        formControlName="amountUnit"
      >
      </ppa-select>
      <div *ngIf="(form.submitted || controls.amountUnit.touched) && controls.amountUnit.invalid">
        <div class="form-error" *ngIf="controls.amountUnit.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
