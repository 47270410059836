<form [formGroup]="locationForm">
  <mat-slide-toggle formControlName="useCurrentLocation" (change)="toggleUseCurrentLocation($event)">
    {{ 'modules.location.modal.location_label' | transloco }}
  </mat-slide-toggle>

  <div class="no-location-error" *ngIf="cantUseCurrentLocation">
    {{ 'modules.location.modal.location_error' | transloco }}
  </div>

  <mat-form-field appearance="fill" *ngIf="!this.dossiers">
    <input
      matInput
      [placeholder]="'modules.location.modal.address_placeholder' | transloco"
      formControlName="address"
      data-cy="create-dossier-selected-location-field"
    />
  </mat-form-field>
</form>

<div class="map-container" *ngIf="mapRender$ | async" [style]="'--route-color: ' + this.routeColor + ';'">
  <mgl-map
    [style]="viewUrl"
    [zoom]="zoom"
    [center]="location"
    (zoomEnd)="zoomEnd($event)"
    (load)="loaded($event)"
    (click)="mapClick($event)"
  >
    <mgl-control *ngIf="!isRoute" mglGeocoder></mgl-control>
  </mgl-map>
</div>

<div class="mb-1">
  <mat-slide-toggle checked (change)="changeView()">{{
    'modules.location.modal.satellite' | transloco
  }}</mat-slide-toggle>
  <button
    *ngIf="!this.dossiers && editable"
    mat-flat-button
    color="warn"
    class="button-remove-location"
    [disabled]="locationCanBeRemoved"
    (click)="clearAddress()"
    data-cy="create-dossier-remove-location"
  >
    {{ 'modules.location.modal.remove_location' | transloco }}
  </button>
</div>

<div>
  <div *ngIf="routeTotalDistance !== ''">
    <strong>{{ 'modules.location.modal.distance' | transloco }}:</strong> {{ routeTotalDistance }}
  </div>
  <div *ngIf="routeTotalDuration !== ''">
    <strong>{{ 'modules.location.modal.duration' | transloco }}:</strong> {{ routeTotalDuration }}
  </div>
  <a
    [href]="locationAppleUrl"
    *ngIf="isRoute && locationAppleUrl !== ''"
    color="default"
    class="button mat-flat-button mb-1"
    target="_blank"
  >
    <i class="uil uil-map"></i>
    {{ 'modules.location.modal.open_location_apple' | transloco }}
  </a>

  <a
    [href]="locationGoogleUrl"
    *ngIf="isRoute && locationGoogleUrl !== ''"
    color="default"
    class="button mat-flat-button mb-1"
    target="_blank"
  >
    <i class="uil uil-map"></i>
    {{ 'modules.location.modal.open_location_google' | transloco }}
  </a>
</div>
